/* eslint-disable @typescript-eslint/lines-between-class-members */
import { GetPodSerialResponse } from "@huxley-medical/huxley-grpc/huxley/transfer/v1/transfer_pb"
import { Message } from "google-protobuf"
import { Any } from "google-protobuf/google/protobuf/any_pb"

/**
 * ProtobufMessageClass is an interface for protobuf message classes passed as values.
 *
 * @interface
 * @param {T} Message
 * @returns {ProtobufMessageClass<T>}
 */
interface ProtobufMessageClass<T extends Message> {
  new (): T
  deserializeBinary(bytes: Uint8Array): T
}

/**
 * CommandRoute is a type for registering command routes.
 *
 * @type
 * @param {ProtobufMessageClass<T>} anyMsg
 * @param {(msg: U) => void} handler
 * @returns {CommandRoute<T, U>}
 */
type CommandRoute<T extends Message, U extends T> = {
  anyMsg: ProtobufMessageClass<T>
  handler: (msg: U) => void
}

/**
 * SansaSerialCommandResponder is a class for routing protobuf commands.
 *
 * @class
 * @returns {SansaSerialCommandResponder}
 */
export class SansaSerialCommandResponder {
  // private map of command type to handler class
  private commandRoutes: Map<string, CommandRoute<Message, any>> = new Map()

  // for sending commands
  private port: SerialPort

  // eslint-disable-next-line class-methods-use-this
  private GetPodSerialResponseHandler(resp: GetPodSerialResponse) {
    console.log("getSerialId:", resp.getSerialId())
  }

  // register all command routes
  constructor(port: SerialPort) {
    this.port = port

    this.registerCommandRoute("huxley.transfer.v1.GetPodSerialResponse", {
      anyMsg: GetPodSerialResponse,
      handler: this.GetPodSerialResponseHandler,
    })
  }

  // public downloadStudy(study: Study) {}

  /**
   * registerCommandRoute registers a command route.
   *
   * @param {string} typeUrl
   * @param {CommandRoute<T, U>} route
   * @returns {void}
   */
  public registerCommandRoute<T extends Message, U extends T>(
    typeUrl: string,
    route: CommandRoute<T, U>
  ): void {
    this.commandRoutes.set(typeUrl, route)
  }

  public routeAnyCommand(cmd: Any) {
    const typeUrl = cmd.getTypeUrl()

    const typeName = typeUrl.split("/").pop()
    if (typeName === undefined) {
      throw new Error("invalid type url")
    }

    // Call the appropriate handler for the command
    const handler = this.commandRoutes.get(typeUrl)
    if (handler === undefined) {
      console.log(`no handler for type ${typeName}`)
      return
    }

    // Deserialize the command message
    const MessageClass = handler.anyMsg
    const message = MessageClass.deserializeBinary(cmd.getValue_asU8())

    // Call the handler
    handler.handler(message)
  }
}
