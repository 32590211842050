/* 
Resolution: Await in loop required for retry logic, intended functionality. Function in loop for queue intended.
*/
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-await-in-loop */
import { TaskQueue } from "cwait"
import Bluebird, { Promise } from "bluebird"
import axios, { AxiosResponse, CanceledError } from "axios"
import { FileWithPath } from "react-dropzone"
import { SetterOrUpdater } from "recoil"
import {
  FileType,
  AbortUpload,
  FinishPresignUploadMutationFn,
  PresignUploadPart,
  UploadPart,
} from "../../generated/graphql"
import { sleep } from "../../util"
import { UploadFile, UploadPartHandler, UploadStatus } from "./types"
import { RetryError } from "../../errors"
import { defaultNoError, SnackAlert } from "../../components/SnackAlerts"

/**
 * relFilePath strips the inital forward slash from the file path and returns it
 *
 * @param file
 * @returns {string | undefined} relFilePath
 */
export const relFilePath = (file: FileWithPath): string | undefined => {
  if (file.path?.startsWith("/")) return file.path.substring(1)
  return file.path
}

/**
 * getFileID returns a unique identifer given the file object
 *
 * @param {File} file
 * @returns {string} id
 */
export const getFileID = (file: File): string => {
  return `${file.name}-${file.size}-${file.lastModified}`
}

/**
 * getValidExtension given a filename returns undefined if it's not contained
 * in the FileType enum. Otherwise returns the uppercase extension string that can be
 * cast to FileType enum
 *
 * @param filename
 * @returns {string | undefined}
 */
export const getValidExtension = (filename: string): string | undefined => {
  const fileExt = filename.split(".").pop()?.toUpperCase()
  if (fileExt === undefined) return undefined

  const validType = Object.values(FileType as any).includes(fileExt)
  if (!validType) return undefined

  return fileExt
}

type UploadPartInputParams = {
  uploadFile: Blob
  chunkSize: number
  putAbortController: AbortController
  onUploadProgress: ((progressEvent: any) => void) | undefined
  onPut:
    | ((
        resp: AxiosResponse<any, any> | undefined,
        err: any | undefined
      ) => void)
    | undefined
}

/**
 * handlePartUpload slices the given uploadFile with chunkSize, and invokes axios.put
 * to start the upload
 *
 * @param {UploadPartInputParams} uploadPartInputParams
 * @returns {UploadPartHandler}
 */
export const handlePartUpload =
  ({
    uploadFile,
    chunkSize,
    putAbortController,
    onUploadProgress,
    onPut,
  }: UploadPartInputParams): UploadPartHandler =>
  async (part: PresignUploadPart): Bluebird<UploadPart | RetryError> => {
    const partNumZeroIndexed = part.partNumber - 1
    const blobPartSlice: Blob = uploadFile.slice(
      partNumZeroIndexed * chunkSize,
      (partNumZeroIndexed + 1) * chunkSize
    )

    // Try to upload chunk
    try {
      const putPartResp = await axios.put(part.url, blobPartSlice, {
        signal: putAbortController.signal,
        onUploadProgress,
      })

      if (onPut !== undefined) onPut(putPartResp, undefined)

      // Build objects containing eTag, part number, and upload ID for finishUpload (complete multipart) request
      const { etag } = putPartResp.headers
      return {
        eTag: etag,
        partNumber: part.partNumber,
      }
    } catch (err: any) {
      if (onPut !== undefined) onPut(undefined, err)

      // Handler errors so a single chunk error doesn't stop remaining uploads
      // so uploadedParts always returns full result array
      if (err instanceof CanceledError) {
        return new Promise((resolve) => {
          resolve(err)
        })
      }

      return new Promise((resolve) => {
        resolve(new RetryError(part))
      })
    }
  }

type UploadPartsParams = {
  bucket: string
  key: string
  parts: PresignUploadPart[]
  totalUploadSize: number
  priorUploadedSize?: number
  uploadFile: UploadFile
  putAbortController: React.MutableRefObject<AbortController | undefined>
  uploadConcurrency?: number
  setUploadFileStatus: (f: UploadFile, status: UploadStatus) => void
  setErrorMsg: SetterOrUpdater<SnackAlert>
  setProgress: React.Dispatch<React.SetStateAction<number>>
  setByteRate: React.Dispatch<React.SetStateAction<number>>
  setUploadAbort: React.Dispatch<React.SetStateAction<AbortUpload | null>>
  finishUpload: FinishPresignUploadMutationFn
}

/**
 * uploadFileParts will upload the given file in parts concurrently.
 * Will automatically handle retrys and update UI state.
 *
 * @param {UploadPartsParams} uploadPartsParams
 * @returns {Promise<void>}
 */
export const uploadFileParts = async ({
  bucket,
  key,
  parts,
  totalUploadSize,
  priorUploadedSize = 0,
  uploadConcurrency = 4,
  uploadFile,
  putAbortController,
  setUploadFileStatus,
  setErrorMsg,
  setProgress,
  setByteRate,
  setUploadAbort,
  finishUpload,
}: UploadPartsParams): Promise<void> => {
  // Keep track up number of bytes uploaded
  let bytesUploaded = 0

  let toUploadParts = parts

  const doneParts: UploadPart[] = []

  let shouldRetry = true
  let retryTimeoutSecs = 2

  const numParts = toUploadParts.length
  const chunkSize = Math.ceil(uploadFile.file.size / numParts)

  setUploadFileStatus(uploadFile, "inprogress")

  // Start retry loop
  while (shouldRetry) {
    const queue = new TaskQueue(Promise, uploadConcurrency)

    // Return in main object response instead? Would require schema change
    const { uploadID } = toUploadParts[0]

    // Setup state info required for aborting uploads
    setUploadAbort({
      uploadID,
      objectBucket: bucket,
      objectKey: key,
    })

    // For calculating throughput
    const secondsSinceUploadStart = Math.round(Date.now() / 1000)

    const queuePromise = Promise.map(
      // The chunks array containing presigned URLs
      toUploadParts,

      // Start chunk upload handler
      queue.wrap((part: PresignUploadPart) => {
        // number of bytes uploaded per chunk
        let bytesUploadedRel = 0

        return handlePartUpload({
          uploadFile: uploadFile.file,
          chunkSize,
          putAbortController: putAbortController.current as AbortController,

          onUploadProgress: (progressEvent) => {
            // Advance progress bar
            const addBytes = progressEvent.loaded - bytesUploadedRel
            bytesUploadedRel += addBytes
            bytesUploaded += addBytes

            // Calculate throughput
            const secondsNow = Math.round(Date.now() / 1000)
            const elapsedSeconds = secondsNow - secondsSinceUploadStart || 1

            // Update progress bar and throughput UI
            setByteRate(bytesUploaded / elapsedSeconds)
            setProgress(
              ((bytesUploaded + priorUploadedSize) / totalUploadSize) * 100
            )
          },
          onPut: (putResp, err) => {
            if (err === undefined) {
              // Clear out retry error messages
              setErrorMsg(defaultNoError)
            } else {
              // Remove the bytes that failed from total bytesUploaded
              // and set progress
              bytesUploaded -= bytesUploadedRel
              setProgress(
                ((bytesUploaded + priorUploadedSize) / totalUploadSize) * 100
              )
            }
          },
        })(part)
      })
    )

    // Start uploading chunks using queue
    const uploadedParts = await queuePromise

    /*
      Problem:
      network errors take a long time to timeout in Chrome upon wifi turn off, retry would take a long time to init, could be a few minutes
      Setting timeout in axios doesn't work, since it's for the entire request (upload and download)
      need to add custom logic to cancel XHR requests when time between invoking request, and request.readyState 0 - 1
      to detect network timeouts manually 
      */

    const retryErrors = uploadedParts.filter(
      (p) => p instanceof RetryError
    ) as RetryError[]

    const hasCancelErrors =
      uploadedParts.filter((p) => p instanceof CanceledError).length > 0

    // Get the parts that were uploaded successfully
    const successParts = uploadedParts.filter(
      (p) => (p as UploadPart).eTag !== undefined
    ) as UploadPart[]

    // Retry errors detected!
    if (retryErrors.length > 0) {
      doneParts.push(...successParts)

      // Set parts to new array containing parts to retry
      toUploadParts = retryErrors.map((o) => o.part)

      // Update UI
      setProgress((priorUploadedSize / totalUploadSize) * 100)
      setErrorMsg({
        open: true,
        message: `Error occurred in ${retryErrors.length} part uploads. retrying in ${retryTimeoutSecs} seconds...`,
      })

      await sleep(retryTimeoutSecs)

      // parabolic timeout, 60 seconds max
      if (retryTimeoutSecs < 30) retryTimeoutSecs *= 2

      // Cancelled by user
    } else if (hasCancelErrors) {
      // break retry loop
      shouldRetry = false

      // update UI
      setProgress(0)
      setErrorMsg({ open: true, message: `Upload cancelled` })

      setUploadFileStatus(uploadFile, "error")

      // finished upload!
    } else {
      // break retry loop
      shouldRetry = false

      doneParts.push(...successParts)

      // finalize upload
      await finishUpload({
        variables: {
          objectBucket: bucket,
          objectKey: key,
          uploadID,

          // Sort parts as S3 requires it, they can become out of order with retries
          parts: doneParts.sort((a, b) => a.partNumber - b.partNumber),
        },
      })

      setUploadFileStatus(uploadFile, "complete")
    }
  }
}
