import { IconButton, Tooltip, styled } from "@mui/material"
import moment from "moment"
import prettyBytes from "pretty-bytes"
import BedtimeIcon from "@mui/icons-material/Bedtime"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"
import { Study } from "@huxley-medical/huxley-grpc/huxley/transfer/v1/transfer_pb"
import { useState } from "react"
import { StudyDownloadManager } from "./utils/serial/StudyDownloadManager"
import DeletePromptDialog from "./DeletePrompt"

const StyledDuration = styled("div")({
  fontSize: "0.8rem",
  color: "#666",
})

const StyledStudySize = styled("div")({
  fontSize: "0.8rem",
  color: "#666",
})

const StyledProgressIndicator = styled("div")({
  position: "absolute",
  top: "0",
  left: "0",
  height: "3px",
  backgroundColor: "rgb(48, 161, 80)",
})

const StyledStudyNameBadge = styled("div")({
  backgroundColor: "#c3c3c3",
  padding: "5px",
  paddingRight: "10px",
  paddingLeft: "10px",
  borderRadius: "5px",
  fontSize: "0.8rem",
  color: "#FFF",
  fontWeight: "bold",
  boxShadow:
    "0px 0px 2px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%)",
})

const OffloadStudy = styled("div")({
  display: "flex",
  position: "relative",
  columnGap: "10px",
  marginBottom: "15px",
  backgroundColor: "#f9f9f9",
  width: "100%",

  alignItems: "center",
  boxShadow:
    "0px 2px 1px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%)",
  borderRadius: "8px",
  padding: "15px",
  "& .MuiButton-root": {
    // float: "right",
    textTransform: "none",
  },
  "& .study-size": {
    fontSize: "0.8rem",
    lineHeight: "30px",
  },
})

const StyledStudyTime = styled("div")({
  fontSize: "0.8rem",
  color: "#666",
  flex: "1",
})

type StydyDownloadProps = {
  study: Study
  connected: boolean
  dlMgr: StudyDownloadManager
  isDownloading: boolean
  refreshStudies: () => Promise<void>
}

export default function StudyDownloadCard({
  study,
  connected,
  dlMgr,
  isDownloading,
  refreshStudies,
}: StydyDownloadProps) {
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const setDownloadProgressAsync = (progress: number) =>
    setDownloadProgress(progress)

  const handleStudyDownload = () =>
    dlMgr.downloadStudy(study, setDownloadProgressAsync, () => {
      setDownloadProgress(0)
    })

  const handleStudyDelete = () =>
    dlMgr.deleteStudy(study, () => {
      // call parent refresh
      refreshStudies()
    })

  const startTime = study.getStartTime()?.toDate() as Date
  const endTime = study.getEndTime()?.toDate() as Date

  const duration = moment.duration(endTime.getTime() - startTime.getTime())

  const progressCss =
    downloadProgress > 0
      ? {
          width: `${downloadProgress}%`,
        }
      : {}

  return (
    <OffloadStudy key={study.getStudyTimestamp()}>
      <StyledProgressIndicator sx={progressCss} />
      <BedtimeIcon style={{ color: "#EBC815" }} />
      <StyledStudyNameBadge>
        {study.getStudyTimestamp()}.hdp
      </StyledStudyNameBadge>
      <StyledStudyTime>
        {moment(startTime).format("MM/DD/YY hh:mm:ss A")} -{" "}
        {moment(endTime).format("hh:mm:ss A")}
      </StyledStudyTime>
      <StyledDuration>{duration.humanize()}</StyledDuration>
      <StyledStudySize className="study-size">
        {prettyBytes(study.getTotalSize())}
      </StyledStudySize>
      <div>
        <Tooltip title="Upload study to cloud (in development)" placement="top">
          <span>
            <IconButton
              disabled
              // disabled={!connected || isDownloading}
              onClick={handleStudyDownload}
            >
              <CloudUploadIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Download study locally" placement="top">
          <IconButton
            disabled={!connected || isDownloading}
            onClick={handleStudyDownload}
          >
            <DownloadForOfflineIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete study from Sansa Pod" placement="top">
          <IconButton
            disabled={!connected || isDownloading}
            className="delete-study-btn"
            aria-label="delete"
            onClick={() => setDeleteDialogOpen(true)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
        <DeletePromptDialog
          studyName={`${study.getStudyTimestamp()}.hdp`}
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={handleStudyDelete}
        />
      </div>
    </OffloadStudy>
  )
}
