import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** gqlgen built-in scalar */
  Map: any;
  /** gqlgen built-in scalar */
  Time: any;
};

/** AbortUpload is an input type used in calls to abortPresignUpload mutation, containing information required by S3 to abort an upload */
export type AbortUpload = {
  /** objectBucket is required by wormholed for finishPresignUpload or abortPresignUpload */
  objectBucket: Scalars['String'];
  /** objectKey is required by wormholed for finishPresignUpload or abortPresignUpload */
  objectKey: Scalars['String'];
  /** uploadID is generated by S3 during createPresignUpload, and is required input for finishPresignUpload or abortPresignUpload */
  uploadID: Scalars['ID'];
};

/** AssetUploadRequest is an input type to initate a multipart upload to S3 */
export type AssetUploadRequest = {
  /** fileName is extracted client-side from upload files, wormholed attaches to the S3 object's metadata */
  fileName: Scalars['String'];
  /** objectSize is the number of bytes for desired object/file upload size expressed as an Int, used by the wormholed service to determine the number of presign URLs to generate for UploadPart (wormholed determines chunk size) */
  objectSize: Scalars['Int'];
  /** subjectID and studyTimestamp are extracted client-side from .hst files, and wormholed attaches to the S3 object's metadata and used currently for S3 key path generation */
  studyID: Scalars['String'];
};

/** CompleteUpload is an input type used in calls to finishPresignUpload mutation, containing information required by S3 to finalize an upload */
export type CompleteUpload = {
  /** objectBucket is required by wormholed for finishPresignUpload */
  objectBucket: Scalars['String'];
  /** objectKey is required by wormholed for finishPresignUpload */
  objectKey: Scalars['String'];
  /** parts is required by wormholed for finishPresignUpload, a list of ETags and part numbers of chunks that have been uploaded successfully */
  parts: Array<UploadPart>;
  /** uploadID is generated by S3 during createPresignUpload, and is required input for finishPresignUpload or abortPresignUpload */
  uploadID: Scalars['ID'];
};

/** CreatePresignUploadResponse is a response type returned by calls to createPresignUpload mutation, containing information required by S3 for presigned UploadPart requests" */
export type CreatePresignUploadResponse = {
  __typename?: 'CreatePresignUploadResponse';
  /** bucket is required in subsequent requests to abortPresignUpload and finishPresignUpload */
  bucket: Scalars['String'];
  /** key is required in subsequent requests to abortPresignUpload and finishPresignUpload */
  key: Scalars['String'];
  /** parts is an array of presigned URLs for UploadPart S3 requests */
  parts: Array<PresignUploadPart>;
  /** studyID is required for subsequent requests to invoke processing */
  studyID: Scalars['String'];
};

/** FileType designates the type of file uploaded */
export enum FileType {
  /** HDP is the modernized Huxley sleep test file */
  Hdp = 'HDP',
  /** HST is a legacy Huxley sleep test file */
  Hst = 'HST'
}

/** Investigation represents a clinical investigation or trial */
export type Investigation = {
  __typename?: 'Investigation';
  /** id is the site uuid */
  id: Scalars['String'];
  /** name is the site name */
  name: Scalars['String'];
};

/** Root Query type for Apollo spec */
export type Mutation = {
  __typename?: 'Mutation';
  /** abortPresignUpload aborts an in-progress multipart upload */
  abortPresignUpload: Scalars['Boolean'];
  /** createPresignAssetUpload initates a presigned, multipart upload to S3, it returns a CreatePresignUploadResponse with list of PresignUploadPart containing presigned URLs to be used for uploading chunks */
  createPresignAssetUpload: CreatePresignUploadResponse;
  /** createPresignStudyUpload initates a presigned, multipart upload to S3, it returns a CreatePresignUploadResponse with list of PresignUploadPart containing presigned URLs to be used for uploading chunks */
  createPresignStudyUpload: CreatePresignUploadResponse;
  /** finishPresignUpload finalizes a multipart upload once all chunks are uploaded */
  finishPresignUpload: Scalars['String'];
};


/** Root Query type for Apollo spec */
export type MutationAbortPresignUploadArgs = {
  abort: AbortUpload;
};


/** Root Query type for Apollo spec */
export type MutationCreatePresignAssetUploadArgs = {
  req: AssetUploadRequest;
};


/** Root Query type for Apollo spec */
export type MutationCreatePresignStudyUploadArgs = {
  req: StudyUploadRequest;
};


/** Root Query type for Apollo spec */
export type MutationFinishPresignUploadArgs = {
  complete: CompleteUpload;
};

/** PresignUploadPart is a response type returned by calls to createPresignUpload mutation within createPresignUploadResponse, containing information required by S3 for presigned UploadPart requests" */
export type PresignUploadPart = {
  __typename?: 'PresignUploadPart';
  /** eTag is generated by S3 during createPresignUpload, and is required input for finishPresignUpload or abortPresignUpload */
  eTag: Scalars['String'];
  /** method is the HTTP method that must be used for presigned requests to be valid */
  method: Scalars['String'];
  /** eTag is generated by S3 during createPresignUpload, and is required input for finishPresignUpload */
  partNumber: Scalars['Int'];
  /** signedHeaders is the list of headers that must be included for presigned requests to be valid */
  signedHeaders: Scalars['Map'];
  /** uploadID is generated by S3 during createPresignUpload, and is required input for finishPresignUpload or abortPresignUpload */
  uploadID: Scalars['String'];
  /** url is the presigned URL used for uploading object chunks */
  url: Scalars['String'];
};

export type ProcessingInfo = {
  __typename?: 'ProcessingInfo';
  dspPipelineVersion: Scalars['String'];
  endTime: Scalars['Time'];
  fileFormatVersion: Scalars['String'];
  startTime: Scalars['Time'];
};

export type ProcessingStatus = {
  __typename?: 'ProcessingStatus';
  info?: Maybe<ProcessingInfo>;
  message: Scalars['String'];
  progress: Scalars['Int'];
  status: Scalars['String'];
  timestamp: Scalars['Time'];
};

/** Root Query type for Apollo spec */
export type Query = {
  __typename?: 'Query';
  /** currentUser represents current user information from OIDC claim */
  currentUser?: Maybe<User>;
  /** investigations lists the investigations dropdown */
  investigations: Array<Investigation>;
  /** sites lists the sites for study site upload dropdown */
  sites: Array<Site>;
  /** studies lists the studies for asset upload dropdown */
  studies: Array<Study>;
  /** subjects lists the subjects for study upload dropdown suggestion */
  subjects: Array<Subject>;
};

/** Site represents a Huxley sleep site */
export type Site = {
  __typename?: 'Site';
  /** id is the site uuid */
  id: Scalars['String'];
  /** name is the site name */
  name: Scalars['String'];
};

/** Study represents a Huxley sleep test */
export type Study = {
  __typename?: 'Study';
  /** endTime of study */
  endTime: Scalars['Time'];
  /** id is the study uuid */
  id: Scalars['String'];
  /** investigationId is the id (uuid) of the investigation record */
  investigationId: Scalars['String'];
  /** investigationName is the name of the investigation record */
  investigationName: Scalars['String'];
  /** patientId is the id (uuid) of the patient record */
  patientId: Scalars['String'];
  /** patientIdentifier is the identifier of the patient record */
  patientIdentifier: Scalars['String'];
  /** siteId is the id (uuid) of the site record */
  siteId: Scalars['String'];
  /** siteName is the uuid of the site record */
  siteName: Scalars['String'];
  /** startTime of study */
  startTime: Scalars['Time'];
};

/** StudyUploadRequest is an input type to initate a multipart upload to S3 */
export type StudyUploadRequest = {
  /** fileName is extracted client-side from upload files, wormholed attaches to the S3 object's metadata */
  fileName: Scalars['String'];
  /** fileType designates the type of file uploaded, wormholed uses for S3 path extension generation */
  fileType: FileType;
  /** firmwareConfigJSON holds the json string extracted from the HDP file */
  firmwareConfigJSON: Scalars['String'];
  /** investigationID references the clinical investigation or trial */
  investigationID: Scalars['String'];
  /** objectSize is the number of bytes for desired object/file upload size expressed as an Int, used by the wormholed service to determine the number of presign URLs to generate for UploadPart (wormholed determines chunk size) */
  objectSize: Scalars['Int'];
  /** studySiteID references the physical location record in which the study took place, and wormholed attaches to the S3 object's metadata and used currently for S3 key path generation */
  studySiteID: Scalars['String'];
  /** subjectID and studyTimestamp are extracted client-side from .hst files, and wormholed attaches to the S3 object's metadata */
  studyTimestamp: Scalars['Time'];
  /** studyTimezone represents the timezone in which the study took place */
  studyTimezone: Timezone;
  /** subjectID and studyTimestamp are extracted client-side from .hst files, and wormholed attaches to the S3 object's metadata */
  subjectID: Scalars['String'];
  /** tags is used to contain additional metadata */
  tags: Array<Scalars['String']>;
};

/** Subject represents a Huxley sleep test person or patient */
export type Subject = {
  __typename?: 'Subject';
  /** id is the subject uuid */
  id: Scalars['String'];
  /** identifier is the subject name or other unique identifier */
  identifier: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  invokeProcessing: ProcessingStatus;
};


export type SubscriptionInvokeProcessingArgs = {
  studyID: Scalars['String'];
};

/** Timezone object contains information about a timezone */
export type Timezone = {
  /** The timezone 3-letter abbreviation */
  abbreviation: Scalars['String'];
  /** The timezone name */
  name: Scalars['String'];
  /** The timezone offset seconds from UTC */
  offsetSecs: Scalars['Int'];
};

/** UploadPart is an input type used in calls to finishPresignUpload mutation, containing a subset of information required by S3 to finalize an upload */
export type UploadPart = {
  /** eTag information is returned by createPresignUpload, is required input for finishPresignUpload or abortPresignUpload */
  eTag: Scalars['String'];
  /** partNumber information is returned by createPresignUpload */
  partNumber: Scalars['Int'];
};

/** User contains OIDC claim information extracted from the client-provided JWT token */
export type User = {
  __typename?: 'User';
  /** Access token hash. Provides validation that the access token is tied to the identity token. If the ID token is issued with an access_token value in the server flow, this claim is always included. This claim can be used as an alternate mechanism to protect against cross-site request forgery attacks, but if you follow Step 1 and Step 3 it is not necessary to verify the access token. */
  accessTokenHash?: Maybe<Scalars['String']>;
  /** The audience that this ID token is intended for. It must be one of the OAuth 2.0 client IDs of your application */
  audience?: Maybe<Scalars['String']>;
  /** The client_id of the authorized presenter. This claim is only needed when the party requesting the ID token is not the same as the audience of the ID token. This may be the case at Google for hybrid apps where a web application and Android app have a different OAuth 2.0 client_id but share the same Google APIs project. */
  authorizedPresenter?: Maybe<Scalars['String']>;
  /** The domain associated with the Google Cloud organization of the user */
  domain?: Maybe<Scalars['String']>;
  /** The user's email address. This value may not be unique to this user and is not suitable for use as a primary key */
  email?: Maybe<Scalars['String']>;
  /** True if the user's e-mail address has been verified; otherwise false */
  emailVerified?: Maybe<Scalars['Boolean']>;
  /** Expiration time on or after which the ID token must not be accepted. Represented in Unix time (integer seconds) */
  expiresAt?: Maybe<Scalars['Int']>;
  /** The user's surname(s) or last name(s) */
  familyName?: Maybe<Scalars['String']>;
  /** The user's given name(s) or first name(s) */
  givenName?: Maybe<Scalars['String']>;
  /** The time the ID token was issued. Represented in Unix time (integer seconds) */
  issuedAt?: Maybe<Scalars['Int']>;
  /** The user's locale, represented by a BCP 47 language tag */
  locale?: Maybe<Scalars['String']>;
  /** The user's full name, in a displayable form */
  name?: Maybe<Scalars['String']>;
  /** The URL of the user's profile picture */
  pictureURL?: Maybe<Scalars['String']>;
  /** An identifier for the user, unique among all Google accounts and never reused. A Google account can have multiple email addresses at different points in time, but the sub value is never changed. Use sub within your application as the unique-identifier key for the user. Maximum length of 255 case-sensitive ASCII characters. */
  subjectIdentifier?: Maybe<Scalars['String']>;
};

export type CreatePresignStudyUploadMutationVariables = Exact<{
  fileName: Scalars['String'];
  fileType: FileType;
  objSize: Scalars['Int'];
  subjectId: Scalars['String'];
  studySiteId: Scalars['String'];
  investigationId: Scalars['String'];
  firmwareConfigJSON: Scalars['String'];
  timestamp: Scalars['Time'];
  tags: Array<Scalars['String']> | Scalars['String'];
  studyTimezone: Timezone;
}>;


export type CreatePresignStudyUploadMutation = { __typename?: 'Mutation', createPresignStudyUpload: { __typename?: 'CreatePresignUploadResponse', studyID: string, bucket: string, key: string, parts: Array<{ __typename?: 'PresignUploadPart', uploadID: string, partNumber: number, eTag: string, url: string, signedHeaders: any, method: string }> } };

export type CreatePresignAssetUploadMutationVariables = Exact<{
  fileName: Scalars['String'];
  objSize: Scalars['Int'];
  studyId: Scalars['String'];
}>;


export type CreatePresignAssetUploadMutation = { __typename?: 'Mutation', createPresignAssetUpload: { __typename?: 'CreatePresignUploadResponse', bucket: string, key: string, parts: Array<{ __typename?: 'PresignUploadPart', uploadID: string, partNumber: number, eTag: string, url: string, signedHeaders: any, method: string }> } };

export type FinishPresignUploadMutationVariables = Exact<{
  objectBucket: Scalars['String'];
  objectKey: Scalars['String'];
  uploadID: Scalars['ID'];
  parts: Array<UploadPart> | UploadPart;
}>;


export type FinishPresignUploadMutation = { __typename?: 'Mutation', finishPresignUpload: string };

export type AbortPresignUploadMutationVariables = Exact<{
  objectBucket: Scalars['String'];
  objectKey: Scalars['String'];
  uploadID: Scalars['ID'];
}>;


export type AbortPresignUploadMutation = { __typename?: 'Mutation', abortPresignUpload: boolean };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', email?: string | null, name?: string | null, expiresAt?: number | null } | null };

export type GetStudiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudiesQuery = { __typename?: 'Query', studies: Array<{ __typename?: 'Study', id: string, investigationId: string, investigationName: string, siteId: string, siteName: string, patientId: string, patientIdentifier: string, startTime: any, endTime: any }> };

export type GetSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSitesQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: string, name: string }> };

export type GetSubjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubjectsQuery = { __typename?: 'Query', subjects: Array<{ __typename?: 'Subject', id: string, identifier: string }> };

export type GetInvestigationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvestigationsQuery = { __typename?: 'Query', investigations: Array<{ __typename?: 'Investigation', id: string, name: string }> };

export type InvokeProcessingSubscriptionVariables = Exact<{
  studyID: Scalars['String'];
}>;


export type InvokeProcessingSubscription = { __typename?: 'Subscription', invokeProcessing: { __typename?: 'ProcessingStatus', progress: number, status: string, timestamp: any, message: string, info?: { __typename?: 'ProcessingInfo', startTime: any, endTime: any, dspPipelineVersion: string, fileFormatVersion: string } | null } };


export const CreatePresignStudyUploadDocument = gql`
    mutation CreatePresignStudyUpload($fileName: String!, $fileType: FileType!, $objSize: Int!, $subjectId: String!, $studySiteId: String!, $investigationId: String!, $firmwareConfigJSON: String!, $timestamp: Time!, $tags: [String!]!, $studyTimezone: Timezone!) {
  createPresignStudyUpload(
    req: {fileName: $fileName, fileType: $fileType, objectSize: $objSize, studySiteID: $studySiteId, firmwareConfigJSON: $firmwareConfigJSON, investigationID: $investigationId, subjectID: $subjectId, studyTimestamp: $timestamp, studyTimezone: $studyTimezone, tags: $tags}
  ) {
    studyID
    bucket
    key
    parts {
      uploadID
      partNumber
      eTag
      url
      signedHeaders
      method
    }
  }
}
    `;
export type CreatePresignStudyUploadMutationFn = Apollo.MutationFunction<CreatePresignStudyUploadMutation, CreatePresignStudyUploadMutationVariables>;

/**
 * __useCreatePresignStudyUploadMutation__
 *
 * To run a mutation, you first call `useCreatePresignStudyUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignStudyUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignStudyUploadMutation, { data, loading, error }] = useCreatePresignStudyUploadMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *      objSize: // value for 'objSize'
 *      subjectId: // value for 'subjectId'
 *      studySiteId: // value for 'studySiteId'
 *      investigationId: // value for 'investigationId'
 *      firmwareConfigJSON: // value for 'firmwareConfigJSON'
 *      timestamp: // value for 'timestamp'
 *      tags: // value for 'tags'
 *      studyTimezone: // value for 'studyTimezone'
 *   },
 * });
 */
export function useCreatePresignStudyUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresignStudyUploadMutation, CreatePresignStudyUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresignStudyUploadMutation, CreatePresignStudyUploadMutationVariables>(CreatePresignStudyUploadDocument, options);
      }
export type CreatePresignStudyUploadMutationHookResult = ReturnType<typeof useCreatePresignStudyUploadMutation>;
export type CreatePresignStudyUploadMutationResult = Apollo.MutationResult<CreatePresignStudyUploadMutation>;
export type CreatePresignStudyUploadMutationOptions = Apollo.BaseMutationOptions<CreatePresignStudyUploadMutation, CreatePresignStudyUploadMutationVariables>;
export const CreatePresignAssetUploadDocument = gql`
    mutation createPresignAssetUpload($fileName: String!, $objSize: Int!, $studyId: String!) {
  createPresignAssetUpload(
    req: {fileName: $fileName, objectSize: $objSize, studyID: $studyId}
  ) {
    bucket
    key
    parts {
      uploadID
      partNumber
      eTag
      url
      signedHeaders
      method
    }
  }
}
    `;
export type CreatePresignAssetUploadMutationFn = Apollo.MutationFunction<CreatePresignAssetUploadMutation, CreatePresignAssetUploadMutationVariables>;

/**
 * __useCreatePresignAssetUploadMutation__
 *
 * To run a mutation, you first call `useCreatePresignAssetUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignAssetUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignAssetUploadMutation, { data, loading, error }] = useCreatePresignAssetUploadMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      objSize: // value for 'objSize'
 *      studyId: // value for 'studyId'
 *   },
 * });
 */
export function useCreatePresignAssetUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresignAssetUploadMutation, CreatePresignAssetUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresignAssetUploadMutation, CreatePresignAssetUploadMutationVariables>(CreatePresignAssetUploadDocument, options);
      }
export type CreatePresignAssetUploadMutationHookResult = ReturnType<typeof useCreatePresignAssetUploadMutation>;
export type CreatePresignAssetUploadMutationResult = Apollo.MutationResult<CreatePresignAssetUploadMutation>;
export type CreatePresignAssetUploadMutationOptions = Apollo.BaseMutationOptions<CreatePresignAssetUploadMutation, CreatePresignAssetUploadMutationVariables>;
export const FinishPresignUploadDocument = gql`
    mutation FinishPresignUpload($objectBucket: String!, $objectKey: String!, $uploadID: ID!, $parts: [UploadPart!]!) {
  finishPresignUpload(
    complete: {objectBucket: $objectBucket, objectKey: $objectKey, uploadID: $uploadID, parts: $parts}
  )
}
    `;
export type FinishPresignUploadMutationFn = Apollo.MutationFunction<FinishPresignUploadMutation, FinishPresignUploadMutationVariables>;

/**
 * __useFinishPresignUploadMutation__
 *
 * To run a mutation, you first call `useFinishPresignUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPresignUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPresignUploadMutation, { data, loading, error }] = useFinishPresignUploadMutation({
 *   variables: {
 *      objectBucket: // value for 'objectBucket'
 *      objectKey: // value for 'objectKey'
 *      uploadID: // value for 'uploadID'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useFinishPresignUploadMutation(baseOptions?: Apollo.MutationHookOptions<FinishPresignUploadMutation, FinishPresignUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishPresignUploadMutation, FinishPresignUploadMutationVariables>(FinishPresignUploadDocument, options);
      }
export type FinishPresignUploadMutationHookResult = ReturnType<typeof useFinishPresignUploadMutation>;
export type FinishPresignUploadMutationResult = Apollo.MutationResult<FinishPresignUploadMutation>;
export type FinishPresignUploadMutationOptions = Apollo.BaseMutationOptions<FinishPresignUploadMutation, FinishPresignUploadMutationVariables>;
export const AbortPresignUploadDocument = gql`
    mutation AbortPresignUpload($objectBucket: String!, $objectKey: String!, $uploadID: ID!) {
  abortPresignUpload(
    abort: {objectBucket: $objectBucket, objectKey: $objectKey, uploadID: $uploadID}
  )
}
    `;
export type AbortPresignUploadMutationFn = Apollo.MutationFunction<AbortPresignUploadMutation, AbortPresignUploadMutationVariables>;

/**
 * __useAbortPresignUploadMutation__
 *
 * To run a mutation, you first call `useAbortPresignUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortPresignUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortPresignUploadMutation, { data, loading, error }] = useAbortPresignUploadMutation({
 *   variables: {
 *      objectBucket: // value for 'objectBucket'
 *      objectKey: // value for 'objectKey'
 *      uploadID: // value for 'uploadID'
 *   },
 * });
 */
export function useAbortPresignUploadMutation(baseOptions?: Apollo.MutationHookOptions<AbortPresignUploadMutation, AbortPresignUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AbortPresignUploadMutation, AbortPresignUploadMutationVariables>(AbortPresignUploadDocument, options);
      }
export type AbortPresignUploadMutationHookResult = ReturnType<typeof useAbortPresignUploadMutation>;
export type AbortPresignUploadMutationResult = Apollo.MutationResult<AbortPresignUploadMutation>;
export type AbortPresignUploadMutationOptions = Apollo.BaseMutationOptions<AbortPresignUploadMutation, AbortPresignUploadMutationVariables>;
export const GetUserDocument = gql`
    query GetUser {
  currentUser {
    email
    name
    expiresAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetStudiesDocument = gql`
    query GetStudies {
  studies {
    id
    investigationId
    investigationName
    siteId
    siteName
    patientId
    patientIdentifier
    startTime
    endTime
  }
}
    `;

/**
 * __useGetStudiesQuery__
 *
 * To run a query within a React component, call `useGetStudiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudiesQuery(baseOptions?: Apollo.QueryHookOptions<GetStudiesQuery, GetStudiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudiesQuery, GetStudiesQueryVariables>(GetStudiesDocument, options);
      }
export function useGetStudiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudiesQuery, GetStudiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudiesQuery, GetStudiesQueryVariables>(GetStudiesDocument, options);
        }
export type GetStudiesQueryHookResult = ReturnType<typeof useGetStudiesQuery>;
export type GetStudiesLazyQueryHookResult = ReturnType<typeof useGetStudiesLazyQuery>;
export type GetStudiesQueryResult = Apollo.QueryResult<GetStudiesQuery, GetStudiesQueryVariables>;
export const GetSitesDocument = gql`
    query GetSites {
  sites {
    id
    name
  }
}
    `;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a React component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
      }
export function useGetSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
        }
export type GetSitesQueryHookResult = ReturnType<typeof useGetSitesQuery>;
export type GetSitesLazyQueryHookResult = ReturnType<typeof useGetSitesLazyQuery>;
export type GetSitesQueryResult = Apollo.QueryResult<GetSitesQuery, GetSitesQueryVariables>;
export const GetSubjectsDocument = gql`
    query GetSubjects {
  subjects {
    id
    identifier
  }
}
    `;

/**
 * __useGetSubjectsQuery__
 *
 * To run a query within a React component, call `useGetSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options);
      }
export function useGetSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options);
        }
export type GetSubjectsQueryHookResult = ReturnType<typeof useGetSubjectsQuery>;
export type GetSubjectsLazyQueryHookResult = ReturnType<typeof useGetSubjectsLazyQuery>;
export type GetSubjectsQueryResult = Apollo.QueryResult<GetSubjectsQuery, GetSubjectsQueryVariables>;
export const GetInvestigationsDocument = gql`
    query GetInvestigations {
  investigations {
    id
    name
  }
}
    `;

/**
 * __useGetInvestigationsQuery__
 *
 * To run a query within a React component, call `useGetInvestigationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestigationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestigationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestigationsQuery(baseOptions?: Apollo.QueryHookOptions<GetInvestigationsQuery, GetInvestigationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvestigationsQuery, GetInvestigationsQueryVariables>(GetInvestigationsDocument, options);
      }
export function useGetInvestigationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvestigationsQuery, GetInvestigationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvestigationsQuery, GetInvestigationsQueryVariables>(GetInvestigationsDocument, options);
        }
export type GetInvestigationsQueryHookResult = ReturnType<typeof useGetInvestigationsQuery>;
export type GetInvestigationsLazyQueryHookResult = ReturnType<typeof useGetInvestigationsLazyQuery>;
export type GetInvestigationsQueryResult = Apollo.QueryResult<GetInvestigationsQuery, GetInvestigationsQueryVariables>;
export const InvokeProcessingDocument = gql`
    subscription InvokeProcessing($studyID: String!) {
  invokeProcessing(studyID: $studyID) {
    progress
    status
    timestamp
    message
    info {
      startTime
      endTime
      dspPipelineVersion
      fileFormatVersion
    }
  }
}
    `;

/**
 * __useInvokeProcessingSubscription__
 *
 * To run a query within a React component, call `useInvokeProcessingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvokeProcessingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeProcessingSubscription({
 *   variables: {
 *      studyID: // value for 'studyID'
 *   },
 * });
 */
export function useInvokeProcessingSubscription(baseOptions: Apollo.SubscriptionHookOptions<InvokeProcessingSubscription, InvokeProcessingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InvokeProcessingSubscription, InvokeProcessingSubscriptionVariables>(InvokeProcessingDocument, options);
      }
export type InvokeProcessingSubscriptionHookResult = ReturnType<typeof useInvokeProcessingSubscription>;
export type InvokeProcessingSubscriptionResult = Apollo.SubscriptionResult<InvokeProcessingSubscription>;