import { SetterOrUpdater } from "recoil"
import { SnackAlert } from "../components/SnackAlerts"

export const useThrowError =
  (setErrorMsg: SetterOrUpdater<SnackAlert>) => (errorStr: string) => {
    setErrorMsg({
      open: true,
      message: errorStr,
    })
    throw new Error(errorStr)
  }
