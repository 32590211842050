import {
  Checksum,
  ChecksumAlgorithm,
  Command,
} from "@huxley-medical/huxley-grpc/huxley/transfer/v1/transfer_pb"
import { Any } from "google-protobuf/google/protobuf/any_pb"

/**
 * hashData returns a SHA256 hash of the data
 *
 * @param {Uint8Array} data
 * @param {ChecksumAlgorithm} alg
 * @returns {Promise<Uint8Array>}
 * @throws {Error} if algorithm is invalid
 */
export const hashData = async (
  data: Uint8Array,
  alg: ChecksumAlgorithm
): Promise<Uint8Array> => {
  const algMap: { [key: number]: string | undefined } = {
    [ChecksumAlgorithm.SHA256]: "SHA-256",
  }

  const theAlg = algMap[alg]
  if (theAlg === undefined) {
    throw new Error("checksum algorithm not supported")
  }

  const hashBuffer = await crypto.subtle.digest(theAlg, data)
  return new Uint8Array(hashBuffer)
}

/**
 * createCommandWithChecksum returns a Command with a checksum for the given submessage binary.
 *
 * @param {Uint8Array} subMsg
 * @param {string} type
 * @returns {Promise<Command>}
 */
export const createCommandWithChecksum = async (
  subMsg: Uint8Array,
  type: string
): Promise<Command> => {
  const cmd = new Command()

  const anyMsg = new Any()
  anyMsg.pack(subMsg, type)

  cmd.setRequestMethod(anyMsg)

  const checksum = new Checksum()
  checksum.setAlgorithm(ChecksumAlgorithm.SHA256)
  checksum.setSum(await hashData(subMsg, ChecksumAlgorithm.SHA256))
  cmd.setChecksum(checksum)

  return cmd
}
