import { encode } from "../cobs"

export type SerialConnectArgs = {
  usbVendorId: number
  usbProductId: number
  baudRate: number
}

export const wait = (forMs: number) => {
  return new Promise((resolve) => {
    // Setting 2000 ms time
    setTimeout(() => {
      resolve(undefined)
    }, forMs)
  })
}

/**
 * writeCOBSFrame returns an async function for writing data to specified port
 *
 * @param data
 * @param port
 * @returns {Promise<void>}
 */
export const writeCOBSFrame =
  (data: Uint8Array, port: SerialPort | undefined): (() => Promise<void>) =>
  async () => {
    const writer = port?.writable?.getWriter()

    // Encode COBS frame
    await writer?.write(encode(data, true))

    // Allow the serial port to be closed later.
    writer?.releaseLock()
  }

/**
 * writeAtCommandFrame returns an async function for writing data to specified port
 *
 * @param command
 * @param port
 * @returns {Promise<void>}
 */
export const writeAtCommandFrame =
  (command: string, port: SerialPort | undefined): (() => Promise<void>) =>
  async () => {
    const writer = port?.writable?.getWriter()

    // Encode COBS frame
    await writer?.write(new TextEncoder().encode(`${command}\r\n`))

    // Allow the serial port to be closed later.
    writer?.releaseLock()
  }

/**
 * testIsLegacyCommand attempts to read a legacy command response. If no response is recieved (timeout), it's assumed to be non-legacy
 *
 * @param serialPort
 * @param timeoutSec
 * @returns {Promise<boolean>}
 */
export const testIsLegacyCommand = async (
  serialPort: SerialPort,
  timeoutSec: number
): Promise<boolean> => {
  if (serialPort.readable === null) {
    throw new Error("Serial port reader is null")
  }

  const reader = serialPort.readable.getReader()

  // write legacy @ command, invoke writer in background
  writeAtCommandFrame("@time", serialPort)()

  let hasResponseVal: Uint8Array | undefined

  // Attempt to read, will be success if \r\n command framing
  // is enabled in firmware (writeLegacyCommandFrame)
  reader.read().then((readVal) => {
    hasResponseVal = readVal.value
  })

  // setTimeout promise
  await wait(timeoutSec * 1000)

  const isLegacy = hasResponseVal !== undefined

  // is legacy? close the port and return
  if (isLegacy) {
    reader.releaseLock()
    return true
  }

  // Not legacy, zero out COBS and close port
  // if not called:
  //  TypeError: Failed to execute 'getReader' on 'ReadableStream': ReadableStreamDefaultReader constructor can only accept readable streams that are not yet locked to a reader
  await reader.cancel()

  // reset COBS
  const writer = serialPort?.writable?.getWriter()
  await writer?.write(new Uint8Array([0x0, 0x0]))
  writer?.releaseLock()

  return false
}
