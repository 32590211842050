import { SelectChangeEvent } from "@mui/material"
import moment from "moment"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { SetterOrUpdater } from "recoil"
import { ApolloClient } from "@apollo/client"
import {
  AbortPresignUploadMutationFn,
  AbortUpload,
  CreatePresignStudyUploadMutationFn,
  FileType,
  FinishPresignUploadMutationFn,
  InvokeProcessingDocument,
  InvokeProcessingSubscription,
} from "../../../generated/graphql"
import HSTParser from "../../../utils/hstparser"
import { useThrowError } from "../../../utils/hooks"
import { uploadFileParts, getValidExtension } from "../utils"
import {
  UploadEvent,
  UploadEventHandle,
  UploadFile,
  UploadStatus,
  VoidCallback,
} from "../types"
import { defaultNoError, SnackAlert } from "../../../components/SnackAlerts"

type UploadHandleParams = {
  client: ApolloClient<object>
  subjectID: string
  tags: string[]
  siteID?: string
  investigationID?: string
  timezone: string
  studyTimestamp: string
  putAbortController: React.MutableRefObject<AbortController | undefined>
  hdpMetadata: string

  setUploadFileStatus: (f: UploadFile, status: UploadStatus) => void
  setErrorMsg: SetterOrUpdater<SnackAlert>
  createPresign: CreatePresignStudyUploadMutationFn
  setProgress: React.Dispatch<React.SetStateAction<number>>
  setByteRate: React.Dispatch<React.SetStateAction<number>>
  setUploadAbort: React.Dispatch<React.SetStateAction<AbortUpload | null>>
  finishUpload: FinishPresignUploadMutationFn
}

/**
 * uploadHandler is invoked when the upload button is clicked. It uses S3 multipart to upload contents.
 *
 * @param {UploadHandleParams} uploadHandleParams
 * @returns {UploadEventHandle}
 */
export const uploadHandler =
  ({
    client,
    siteID,
    investigationID,
    tags,
    subjectID,
    timezone,
    studyTimestamp,
    putAbortController,
    hdpMetadata,
    setUploadFileStatus,
    setErrorMsg,
    createPresign,
    setProgress,
    setByteRate,
    setUploadAbort,
    finishUpload,
  }: UploadHandleParams): UploadEventHandle =>
  async (params: UploadEvent) => {
    const { acceptedFiles } = params

    const throwError = useThrowError(setErrorMsg)

    if (siteID === undefined) {
      throwError("You must select a site")
      return
    }

    if (investigationID === undefined) {
      throwError("You must select an investigation")
      return
    }

    // Reset error message for new upload
    setErrorMsg(defaultNoError)

    // Get total upload size (supports multiple files)
    const totalUploadSize = acceptedFiles
      .map((f) => f.file.size)
      .reduce(
        (previousValue: number, currentValue: number) =>
          previousValue + currentValue
      )

    // Only support one file uploaded for now
    const uploadFile = acceptedFiles[0]

    if (subjectID.trim() === "") {
      throwError(`Subject ID must have a value`)
    }

    // Call getValidExtension again (previous was in dropHandler) so we don't have to
    // manage another state variable for fileType between event handlers
    const validExtension = getValidExtension(uploadFile.file.name)

    // New AbortController instance per invocation, so we can reupload
    // after cancel
    // eslint-disable-next-line no-param-reassign
    putAbortController.current = new AbortController()

    const tz = moment.tz(studyTimestamp, timezone)

    if (tz === null) {
      throwError(`Invalid timezone: ${timezone}`)
      return
    }

    // Grab the presigned URLs for PUTing chunks
    const createPresignUploadResp = await createPresign({
      variables: {
        fileName: uploadFile.file.name,
        // No invalid checks, as this was done in dropHandler
        fileType: validExtension as FileType,
        objSize: uploadFile.file.size,

        investigationId: investigationID,
        studySiteId: siteID,
        tags,
        firmwareConfigJSON: hdpMetadata,

        subjectId: subjectID.trim(),
        timestamp: studyTimestamp,
        studyTimezone: {
          abbreviation: tz.zoneAbbr(),
          offsetSecs: tz.utcOffset() * 60,
          name: timezone,
        },
      },
    })

    if (
      createPresignUploadResp.data === null ||
      createPresignUploadResp.data === undefined
    )
      return

    await uploadFileParts({
      bucket: createPresignUploadResp.data.createPresignStudyUpload.bucket,
      key: createPresignUploadResp.data.createPresignStudyUpload.key,
      parts: createPresignUploadResp.data.createPresignStudyUpload.parts,
      totalUploadSize,
      uploadFile,
      putAbortController,
      setUploadFileStatus,
      setErrorMsg,
      setProgress,
      setByteRate,
      setUploadAbort,
      finishUpload,
    })

    const sub = client.subscribe<InvokeProcessingSubscription>({
      query: InvokeProcessingDocument,
      variables: {
        studyID: createPresignUploadResp.data.createPresignStudyUpload.studyID,
      },
    })

    sub.subscribe({
      next(data) {
        console.log("invokeProcessingResult", data.data?.invokeProcessing)
      },
      error(error) {
        console.error("Error from subscription", error)
      },
      complete() {
        console.log("invokeProcessingResult subscription complete")
        // Your cleanup logic here
      },
    })

    // Upload done
    setProgress(0)
  }

type HandleTZChangeParams = {
  setTimezone: React.Dispatch<React.SetStateAction<string>>
}

type SelectChangeHandle = (event: SelectChangeEvent) => void

/**
 * handleTZChange returns a SelectChangeHandle that updates the timezone state given an input event
 *
 * @param {HandleTZChangeParams} handleTZChangeParams
 * @returns {SelectChangeHandle}
 */
export const handleTZChange =
  ({ setTimezone }: HandleTZChangeParams): SelectChangeHandle =>
  (event: SelectChangeEvent) => {
    const newTZ: string = event.target.value
    // Must set default here, otherwise doesn't render timezone change in DateTimePicker (cache?)
    moment.tz.setDefault(newTZ)
    setTimezone(newTZ)
  }

type AbortUploadHandleParams = {
  uploadAbort: AbortUpload | null
  abortUpload: AbortPresignUploadMutationFn
  putAbortController: React.MutableRefObject<AbortController | undefined>
}

/**
 * abortUploadHandle returns a handler that aborts the given upload
 *
 * @param {AbortUploadHandleParams} abortUploadHandleParams
 * @returns {VoidCallback}
 */
export const abortUploadHandle =
  ({
    uploadAbort,
    abortUpload,
    putAbortController,
  }: AbortUploadHandleParams): VoidCallback =>
  async () => {
    // Abort Axios HTTP requests
    putAbortController.current?.abort()

    if (uploadAbort !== null) {
      // Tell wormholed/S3 to abort
      await abortUpload({
        variables: {
          uploadID: uploadAbort.uploadID,
          objectBucket: uploadAbort.objectBucket,
          objectKey: uploadAbort.objectKey,
        },
      })
    }
  }

type DropHandleParams = {
  setErrorMsg: SetterOrUpdater<SnackAlert>
  setStudyTimestamp: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >
  setHdpMetadata: React.Dispatch<React.SetStateAction<string>>
  momentAdapter: AdapterMoment
  timezone: string
}

/**
 * dropHandler return a handler that validates the study .hdp / .hst file,
 * then extracts and sets the study timestamp.
 *
 * @param {DropHandleParams} dropHandleParams
 * @returns {UploadEventHandle}
 */
export const dropHandler =
  ({
    setErrorMsg,
    setStudyTimestamp,
    setHdpMetadata,
    momentAdapter,
    timezone,
  }: DropHandleParams): UploadEventHandle =>
  async (params: UploadEvent) => {
    const { acceptedFiles, fileRejections } = params

    const throwError = useThrowError(setErrorMsg)

    // Reset error message for new upload
    setErrorMsg(defaultNoError)

    // Show error if we have rejected files
    if (fileRejections.length > 0) {
      throwError(
        `Unable to upload (${fileRejections.length}) files, please upload one file at a time`
      )
    }

    // Only support one file uploaded for now
    const uploadFile = acceptedFiles[0]

    const parser = new HSTParser(uploadFile.file)
    const isValidSansaDataFile = await parser.isValidSansaDataFile()

    if (!isValidSansaDataFile) {
      throwError(`File selected isn't a valid .hst or .hdp file`)
    }

    const validExtension = getValidExtension(uploadFile.file.name)
    if (validExtension === undefined)
      throwError(`File extension must be .hst or .hdp`)

    const [major, minor] = await parser.getVersion()

    if (validExtension === "HST") {
      if (major !== 0 || minor !== 4) {
        throwError(
          `.hst file selected must be v0.4, v${major}.${minor} provided`
        )
      }
    } else if (validExtension === "HDP") {
      if (major !== 0 || minor !== 0) {
        throwError(
          `.hdp file selected must be v0.0, v${major}.${minor} provided`
        )
      }
    }

    const metadata = await parser.getMetadata()

    setHdpMetadata(JSON.stringify(metadata, undefined, 3))
    setStudyTimestamp(
      momentAdapter
        .moment(new Date(metadata.timestamp * 1000))
        .tz(timezone)
        .format()
    )
  }
