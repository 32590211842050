/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { SetterOrUpdater } from "recoil"
import {
  AbortUpload,
  CreatePresignAssetUploadMutationFn,
  FinishPresignUploadMutationFn,
} from "../../../generated/graphql"
import { useThrowError } from "../../../utils/hooks"
import { relFilePath, uploadFileParts } from "../utils"
import {
  UploadEvent,
  UploadEventHandle,
  UploadFile,
  UploadStatus,
} from "../types"
import { defaultNoError, SnackAlert } from "../../../components/SnackAlerts"

type UploadAssetHandlerParams = {
  studyId: string
  putAbortController: React.MutableRefObject<AbortController | undefined>
  setUploadFileStatus: (f: UploadFile, status: UploadStatus) => void
  setErrorMsg: SetterOrUpdater<SnackAlert>
  setProgress: React.Dispatch<React.SetStateAction<number>>
  setByteRate: React.Dispatch<React.SetStateAction<number>>
  setUploadAbort: React.Dispatch<React.SetStateAction<AbortUpload | null>>
  finishUpload: FinishPresignUploadMutationFn
  createPresignAsset: CreatePresignAssetUploadMutationFn
}

/**
 * uploadAssetHandler is invoked when the upload button is clicked. It uses S3 multipart to upload contents.
 *
 * @param {UploadAssetHandlerParams} uploadHandleParams
 * @returns {UploadEventHandle}
 */
export const uploadAssetHandler =
  ({
    setErrorMsg,
    createPresignAsset,
    setProgress,
    setByteRate,
    setUploadAbort,
    finishUpload,
    setUploadFileStatus,
    studyId,
    putAbortController,
  }: UploadAssetHandlerParams): UploadEventHandle =>
  async (params: UploadEvent) => {
    const { acceptedFiles } = params

    const throwError = useThrowError(setErrorMsg)

    if (studyId.trim() === "") {
      throwError("please select a study to upload assets")
    }

    // Reset error message for new upload
    setErrorMsg(defaultNoError)

    // Get total upload size (supports multiple files)
    const totalUploadSize = acceptedFiles
      .map((f) => f.file.size)
      .reduce(
        (previousValue: number, currentValue: number) =>
          previousValue + currentValue
      )

    let priorUploadedSize = 0

    // New AbortController instance per invocation, so we can reupload
    // after cancel
    // eslint-disable-next-line no-param-reassign
    putAbortController.current = new AbortController()

    for (const uploadFile of acceptedFiles) {
      if (putAbortController.current.signal.aborted === true) break

      const filePath = relFilePath(uploadFile.file)

      if (filePath === undefined) {
        throwError("unable to get filepath for upload, browser not supported")
        return
      }

      // Grab the presigned URLs for PUTing chunks
      const createPresignUploadResp = await createPresignAsset({
        variables: {
          fileName: filePath,
          objSize: uploadFile.file.size,
          studyId,
        },
      })

      if (
        createPresignUploadResp.data === null ||
        createPresignUploadResp.data === undefined
      )
        return

      await uploadFileParts({
        bucket: createPresignUploadResp.data.createPresignAssetUpload.bucket,
        key: createPresignUploadResp.data.createPresignAssetUpload.key,
        parts: createPresignUploadResp.data.createPresignAssetUpload.parts,
        totalUploadSize,
        priorUploadedSize,
        uploadFile,
        putAbortController,
        setUploadFileStatus,
        setErrorMsg,
        setProgress,
        setByteRate,
        setUploadAbort,
        finishUpload,
      })

      priorUploadedSize += uploadFile.file.size
    }

    // Upload done
    setProgress(0)
  }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const dropAssetHandler = () => async (params: UploadEvent) => {}
