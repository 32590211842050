/* eslint-disable no-await-in-loop */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  DeleteStudyRequest,
  DeleteStudyResponse,
  FetchStudyChunkByInxRequest,
  FetchStudyChunkByInxResponse,
  FetchStudyRequest,
  FetchStudyResponse,
  Study,
} from "@huxley-medical/huxley-grpc/huxley/transfer/v1/transfer_pb"
import { SansaSerialCommandResponder } from "./SansaSerialCommandResponder"
import { createCommandWithChecksum } from "./utils"
import { writeCOBSFrame } from "."
import { ProtobufError } from "./ProtobufError"

const CHUNK_RESPONSE_TIMEOUT = 2000
const STUDY_STREAM_RESPONSE_TIMEOUT = 500
const MAX_MISSING_CHUNK_RETRIES = 3

export type ProgressCallback = (progress: number) => void

type MissingChunkResolveFunction = (
  value:
    | FetchStudyChunkByInxResponse
    | PromiseLike<FetchStudyChunkByInxResponse>
) => void

export class StudyDownloadManager {
  private port: SerialPort

  private expectedStudy: Study | undefined
  private studyChunks: FetchStudyResponse[] = []
  private downloadTimeout: NodeJS.Timeout | undefined
  private missingChunkPromise: Promise<FetchStudyChunkByInxResponse> | undefined
  private missingChunkResolve: MissingChunkResolveFunction = () => {}
  private onProgress: ProgressCallback | undefined
  private onDownloadDone: (() => void) | undefined
  private onDeleteDone: (() => void) | undefined

  public onDownloadStart: (() => void) | undefined
  public onDownloadEnd: (() => void) | undefined

  constructor(responder: SansaSerialCommandResponder, port: SerialPort) {
    this.port = port
    responder.registerCommandRoute("huxley.transfer.v1.FetchStudyResponse", {
      anyMsg: FetchStudyResponse,
      handler: this.handleChunkResponse.bind(this),
    })
    responder.registerCommandRoute("huxley.transfer.v1.DeleteStudyResponse", {
      anyMsg: DeleteStudyResponse,
      handler: this.handleDeleteResponse.bind(this),
    })
    responder.registerCommandRoute(
      "huxley.transfer.v1.FetchStudyChunkByInxResponse",
      {
        anyMsg: FetchStudyChunkByInxResponse,
        handler: this.handleRetryChunkResponse.bind(this),
      }
    )
  }

  /**
   * resetDownload resets the download manager
   *
   * @returns {void}
   */
  private resetDownload(): void {
    this.expectedStudy = undefined
    this.studyChunks = []
    this.downloadTimeout = undefined
    this.missingChunkPromise = undefined
    this.onProgress = undefined
  }

  /**
   * handleRetryChunkResponse routes a retry chunk response to the missing chunk promise resolve function
   *
   * @param {FetchStudyChunkByInxResponse} resp
   * @returns {void}
   */
  private handleRetryChunkResponse(resp: FetchStudyChunkByInxResponse): void {
    console.log("retry chunk response recieved")
    this.missingChunkResolve(resp)
  }

  /**
   * handleChunkResponse pushes chunk responses to the study chunks array
   * and sets a timeout to finalize the download.
   *
   * @param {FetchStudyResponse} resp
   * @returns {void}
   */
  private handleChunkResponse(resp: FetchStudyResponse): void {
    if (this.expectedStudy === undefined) {
      throw new Error("Recieved chunk response when not downloading")
    }

    this.studyChunks.push(resp)

    // update progress callback
    this.onProgress?.(
      (this.studyChunks.length / this.expectedStudy.getNumChunks()) * 100
    )

    if (this.downloadTimeout !== undefined) {
      clearTimeout(this.downloadTimeout)
    }

    // if we haven't recieved a chunk in STUDY_STREAM_RESPONSE_TIMEOUT, finalize the download
    this.downloadTimeout = setTimeout(
      this.finalizeDownload.bind(this),
      STUDY_STREAM_RESPONSE_TIMEOUT
    )
  }

  /**
   * identifyMissingChunks identifies missing chunks in the study chunks array,
   * given the expected study number of chunks.
   *
   * @returns {number[]} missingChunks
   */
  private identifyMissingChunks(): number[] {
    if (this.expectedStudy === undefined) {
      throw new Error("Called identifyMissingChunks when not downloading")
    }

    // check to see if all of the chunks have been recieved
    // if not, build a list of missing chunks
    const missingChunks: number[] = []
    const numChunks = this.expectedStudy?.getNumChunks()
    if (numChunks === undefined) {
      throw new Error("Expected study has no numChunks")
    }

    for (let i = 0; i < numChunks; i += 1) {
      if (
        this.studyChunks.find((chunk) => chunk.getChunkInx() === i) ===
        undefined
      ) {
        missingChunks.push(i)
      }
    }

    return missingChunks
  }

  /**
   * sendMissingChunkRequest sends a missing chunk request to the pod.
   *
   * @param {number} missingChunkInx
   * @returns {Promise<void>}
   */
  private async sendMissingChunkRequest(
    missingChunkInx: number
  ): Promise<void> {
    const fetchChunkReq = new FetchStudyChunkByInxRequest()
    fetchChunkReq.setChunkInx(missingChunkInx)
    fetchChunkReq.setStudyTimestamp(
      (this.expectedStudy as Study).getStudyTimestamp()
    )
    const cmd = await createCommandWithChecksum(
      fetchChunkReq.serializeBinary(),
      "huxley.transfer.v1.FetchStudyChunkByInxRequest"
    )
    await writeCOBSFrame(cmd.serializeBinary(), this.port)()
  }

  /**
   * requestMissingChunks requests missing chunks from the pod.
   * If the missing chunk promise is not resolved within 2 seconds,
   * the download is reset.
   *
   * @param {number[]} missingChunks
   * @returns {Promise<void>}
   */
  private async requestMissingChunks(missingChunks: number[]): Promise<void> {
    console.log("missing chunks:", missingChunks)

    for (let chunkInx = 0; chunkInx < missingChunks.length; chunkInx += 1) {
      const missingChunkInx = missingChunks[chunkInx]

      let numRetries = 0
      let success = false

      while (numRetries < MAX_MISSING_CHUNK_RETRIES && !success) {
        if (numRetries > 0) {
          console.log("retrying missing chunk:", missingChunkInx)
        }
        success = await this.getMissingChunkWithRetry(missingChunkInx)
        numRetries += 1
      }

      if (!success) {
        throw new ProtobufError(
          `Failed to get missing chunk ${missingChunkInx} after ${MAX_MISSING_CHUNK_RETRIES} retries`
        )
      }
    }
  }

  async getMissingChunkWithRetry(missingChunkInx: number): Promise<boolean> {
    console.log("requesting missing chunk:", missingChunkInx)

    // set up promise to resolve when the missing chunk is recieved
    this.missingChunkPromise = new Promise((resolve, reject) => {
      this.missingChunkResolve = resolve
      setTimeout(() => {
        reject(new Error("Timeout waiting for chunk"))
      }, CHUNK_RESPONSE_TIMEOUT)
    })

    try {
      await this.sendMissingChunkRequest(missingChunkInx)

      const chunk = await this.missingChunkPromise
      if (chunk.getChunkInx() !== missingChunkInx) {
        throw new Error(
          `Recieved chunk ${chunk.getChunkInx()} when expecting chunk ${missingChunkInx}`
        )
      }

      const missingResp = new FetchStudyResponse()
      missingResp.setChunkInx(missingChunkInx)
      missingResp.setChunk(chunk.getChunk())

      this.studyChunks.push(missingResp)

      // update progress callback
      this.onProgress?.(
        (this.studyChunks.length /
          (this.expectedStudy?.getNumChunks() as number)) *
          100
      )

      return true
    } catch (err) {
      console.log("error getting missing chunk:", err)
      return false
    }
  }

  /**
   * finalizeDownload finalizes the download by checking for missing chunks,
   * requesting them, and sending the study chunks to the browser.
   *
   * @returns {Promise<void>}
   */
  private async finalizeDownload(): Promise<void> {
    if (this.expectedStudy === undefined) {
      throw new Error("Called finalizeDownload when not downloading")
    }

    // check to see if all of the chunks have been recieved
    const missingChunks = this.identifyMissingChunks()

    // if there are missing chunks, request them
    if (missingChunks.length > 0) {
      await this.requestMissingChunks(missingChunks)
    }

    // sort the chunks by chunk index
    this.studyChunks.sort((a, b) => a.getChunkInx() - b.getChunkInx())

    // send the chunks to the browser
    const studyData = this.studyChunks.map(
      (chunk) => chunk.getChunk() as Uint8Array
    )

    const stream = new ReadableStream<Uint8Array>({
      start(controller) {
        // Iterate over each Uint8Array in studyData
        // eslint-disable-next-line no-restricted-syntax
        for (const array of studyData) {
          // Enqueue each array into the stream
          controller.enqueue(array)
        }

        // Close the stream
        controller.close()
      },
    })

    // Download the stream to the browser
    this.downloadStreamToBrowser(stream)
  }

  /**
   * downloadStreamToBrowser downloads a stream to the browser, using the expected study timestamp as filename.
   * This is done by creating a temporary URL for the stream,
   * creating a download link, and programmatically clicking the link.
   * The download manager is then reset.
   *
   * @param {ReadableStream<Uint8Array>} stream
   * @returns {void}
   */
  private downloadStreamToBrowser(stream: ReadableStream<Uint8Array>): void {
    // Create a new Response object with the stream as its body
    const response = new Response(stream)

    const self = this
    // Convert the Response object to a Blob
    response.blob().then((blob) => {
      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob)

      // Create a download link
      const link = document.createElement("a")
      link.href = url
      link.download = `${this.expectedStudy?.getStudyTimestamp()}.hdp`

      // Trigger the download by programmatically clicking the link
      link.click()

      // Reset the download manager
      self.resetDownload()

      // Call the onDone callback, we're done!
      self.onDownloadDone?.()
      this.onDownloadEnd?.()
    })
  }

  /**
   * sendFetchStudyRequest sends a fetch study request to the pod.
   *
   * @param study
   * @returns {Promise<void>}
   */
  private async sendFetchStudyRequest(study: Study): Promise<void> {
    // send the request to download the study
    const fetchStudyReq = new FetchStudyRequest()
    fetchStudyReq.setStudyTimestamp(study.getStudyTimestamp())
    const cmd = await createCommandWithChecksum(
      fetchStudyReq.serializeBinary(),
      "huxley.transfer.v1.FetchStudyRequest"
    )
    await writeCOBSFrame(cmd.serializeBinary(), this.port)()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleDeleteResponse(_: DeleteStudyResponse): void {
    this.onDeleteDone?.()
  }

  private async sendDeleteStudyRequest(study: Study): Promise<void> {
    // send the request to delete the study
    const delStudyReq = new DeleteStudyRequest()
    delStudyReq.setStudyTimestamp(study.getStudyTimestamp())
    const cmd = await createCommandWithChecksum(
      delStudyReq.serializeBinary(),
      "huxley.transfer.v1.DeleteStudyRequest"
    )
    await writeCOBSFrame(cmd.serializeBinary(), this.port)()
  }

  /**
   * downloadStudy downloads a study from the pod to the browser.
   *
   * @param {Study} study
   * @returns {Promise<void>}
   */
  public async downloadStudy(
    study: Study,
    progress?: ProgressCallback,
    onDone?: () => void
  ): Promise<void> {
    this.expectedStudy = study
    this.onProgress = progress
    this.onDownloadDone = onDone

    this.onDownloadStart?.()

    await this.sendFetchStudyRequest(study)
  }

  public async deleteStudy(study: Study, onDeleted: () => void): Promise<void> {
    this.onDeleteDone = onDeleted

    await this.sendDeleteStudyRequest(study)
  }
}
