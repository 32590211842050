import { Button, styled } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import PodPanel, { usbProductId, usbVendorId } from "./PodPanel"
import { SansaSerialCommandReader } from "./utils/serial/SansaSerialCommandReader"

const StyledPodConnectionManager = styled("div")({
  marginTop: "40px",
})

export default function PodConnectionManager() {
  const [askForPlugin, setAskForPlugin] = useState<boolean>(false)
  const [avaliablePorts, setAvaliablePorts] = useState<SerialPort[]>([])
  const refreshCount = useRef<number>(0)
  const portCount = useRef<number>(0)
  const openedReaders = useRef<SansaSerialCommandReader[]>([])

  const registerReader = (reader: SansaSerialCommandReader) => {
    openedReaders.current.push(reader)
  }

  const onInit = async () => {
    const ports = await navigator.serial.getPorts()
    refreshCount.current += 1
    // show message to plug in device, if none found
    setAskForPlugin(ports.length === 0)
    setAvaliablePorts(ports)
    portCount.current = ports.length
  }

  const refreshPorts = async () => {
    // Disconnect all readers.
    for (let n = 0; n < openedReaders.current.length; n += 1) {
      // eslint-disable-next-line no-await-in-loop
      await openedReaders.current[n].stopPortReader()
    }

    // clear readers
    openedReaders.current = []

    // wait for all readers to stop
    setTimeout(onInit, 250)
  }

  const onUserConfirmDevicePluggedIn = async () => {
    // requestPort to grant access to devices
    const ports = await navigator.serial.getPorts()

    if (ports.length === 0) {
      try {
        await navigator.serial.requestPort({
          filters: [{ usbVendorId, usbProductId }],
        })
        setAskForPlugin(false)
        await onInit()
      } catch {
        console.log("no port selected")
      }
    }
  }

  useEffect(() => {
    onInit()

    const interval = setInterval(async () => {
      const ports = await navigator.serial.getPorts()
      if (ports.length !== portCount.current) {
        refreshPorts()
      }
    }, 3000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledPodConnectionManager>
      {askForPlugin && (
        <div style={{ textAlign: "center" }}>
          <h4>Please connect a Sansa device via USB</h4>
          <br />
          <Button variant="contained" onClick={onUserConfirmDevicePluggedIn}>
            My Device is connected
          </Button>
        </div>
      )}

      {avaliablePorts.map((port, i) => {
        return (
          <PodPanel
            key={i.toString() + refreshCount.current.toString()}
            refreshPorts={refreshPorts}
            registerReader={registerReader}
            port={port}
          />
        )
      })}
    </StyledPodConnectionManager>
  )
}
