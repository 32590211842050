/* eslint-disable react/require-default-props */
/*
Resolution: Prop spreading is an idomatic pattern for react-dropzone 
*/
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react"
import { Button, CircularProgress } from "@mui/material"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import { useDropzone } from "react-dropzone"
import { useRecoilCallback, useRecoilValue } from "recoil"
import { handleUploadEvent, onDropCallback, uploadClickHandler } from "./events"
import { formatBytes } from "../../../util"
import {
  UploadEvent,
  UploadEventHandle,
  UploadFile,
  UploadStatus,
  VoidCallback,
} from "../types"
import { uploadFiles, uploadIDs } from "../state"
import { getFileID, relFilePath } from "../utils"
import {
  FileSizeLabel,
  StyledDropZone,
  StyledFileList,
  StyledUploadBox,
  StyledUploadItem,
  StyledUploadText,
} from "./components"

const uploadStatusColors = {
  waiting: "#D4ECFE",
  inprogress: "#FFEF9F",
  complete: "#7BF1A8",
  error: "#FFD6E0",
}

type UploadBoxProps = {
  uploadBtnLabel?: string
  children?: React.ReactNode
  label: React.ReactNode
  maxFiles?: number

  onFileDrop: UploadEventHandle
  onUploadClick: UploadEventHandle
  onAbort: VoidCallback
}

type FileListItemParams = {
  uploadFileID: string
}

function FileListItem({ uploadFileID }: FileListItemParams) {
  const uploadFile = useRecoilValue(uploadFiles(uploadFileID))

  return (
    <StyledUploadItem
      key={uploadFile.file.name}
      sx={{
        backgroundColor: uploadStatusColors[uploadFile.status],
      }}
    >
      <InsertDriveFileIcon />
      {relFilePath(uploadFile.file)}{" "}
      <FileSizeLabel>{formatBytes(uploadFile.file.size)}</FileSizeLabel>
      {uploadFile.status === "inprogress" && (
        <CircularProgress
          sx={{
            width: "20px !important",
            height: "20px !important",
            verticalAlign: "middle",
            marginLeft: "10px",
            "& svg": {
              width: "20px",
              height: "20px",
            },
          }}
        />
      )}
    </StyledUploadItem>
  )
}

export default function UploadDropZone({
  children = null,
  label,
  uploadBtnLabel,
  maxFiles = 1,
  onFileDrop,
  onAbort,
  onUploadClick,
}: UploadBoxProps) {
  const [uploadEvent, setUploadEvent] = useState<UploadEvent | undefined>()
  const [globalUploadStatus, setGlobalUploadStatus] =
    useState<UploadStatus>("waiting")

  // Closure for creating events using recoil state
  const setUploadFiles = useRecoilCallback(
    ({ set }) =>
      (newFiles: UploadFile[]) => {
        newFiles.forEach((f) => {
          set(uploadFiles(getFileID(f.file)), f)
        })
        set(
          uploadIDs,
          newFiles.map((f) => getFileID(f.file))
        )
      }
  )

  // handleUploadEvent is syntax sugar, no functionality
  const handleUploadEventCallback = handleUploadEvent({
    // onFileDrop is passed from the parent component, should be used to validate files
    onFileDrop,
  })

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCallback({
      setUploadEvent,
      setUploadFiles,
      setGlobalUploadStatus,
      handleUploadEventCallback,
    }),
    maxFiles,
  })

  const files = uploadEvent?.acceptedFiles.map((upload) => {
    const fileID = getFileID(upload.file)
    return <FileListItem key={fileID} uploadFileID={fileID} />
  })

  return (
    <div className="upload-drop-zone">
      <StyledUploadBox>
        <StyledDropZone data-testid="drop-zone" {...getRootProps()}>
          <input {...getInputProps()} />
          <StyledUploadText>{label}</StyledUploadText>
        </StyledDropZone>

        {(files?.length ?? 0) > 0 && (
          <StyledFileList data-testid="file-list">
            <ul>{files}</ul>
          </StyledFileList>
        )}

        {children}
      </StyledUploadBox>

      {globalUploadStatus === "inprogress" && (
        <Button
          data-testid="cancel-btn"
          variant="outlined"
          color="error"
          onClick={() => {
            // setUploadEvent(undefined)
            setGlobalUploadStatus("waiting")
            onAbort()
          }}
        >
          Cancel
        </Button>
      )}

      <Button
        disabled={
          globalUploadStatus !== "waiting" ||
          uploadEvent === undefined ||
          uploadEvent?.acceptedFiles.length === 0
        }
        data-testid="upload-btn"
        style={{ float: "right" }}
        variant="outlined"
        onClick={uploadClickHandler({
          setGlobalUploadStatus,
          onUploadClick,
          uploadEvent,
        })}
      >
        {uploadBtnLabel || "Upload"}
      </Button>

      <div style={{ clear: "right" }} />
    </div>
  )
}
