import { atom, atomFamily, useRecoilCallback } from "recoil"
import { UploadFile, UploadStatus } from "./types"
import { getFileID } from "./utils"

export const uploadFiles = atomFamily<UploadFile, string>({
  key: "uploadFiles",
  // Fix tests
  dangerouslyAllowMutability: true,
})

export const uploadIDs = atom<string[]>({
  key: "uploadIDs",
  default: [],
})

export const useSetUploadFileStatus = () =>
  useRecoilCallback(({ set }) => (f: UploadFile, status: UploadStatus) => {
    set(uploadFiles(getFileID(f.file)), {
      ...f,
      status,
    })
  })
