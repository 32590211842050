import React, { useContext } from "react"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import { NavLink } from "react-router-dom"
import { Menu, MenuItem } from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import { WormholeUserContext } from "../../context/userContext"
import { getInitials, useAPIBase } from "../../util"

import "./index.css"

type AppMenuParams = { children: React.ReactNode }

export function AppMenu({ children }: AppMenuParams) {
  return <ul className="hux-menu">{children}</ul>
}

type AppMenuItemParams = { children: React.ReactNode; to: string }

export function AppMenuItem({ children, to }: AppMenuItemParams) {
  return (
    <li className="hux-menu-item">
      <NavLink to={to}>{children}</NavLink>
    </li>
  )
}

type StackItemParams = { children: React.ReactNode }

function StackItem({ children }: StackItemParams) {
  return <div className="stack-item">{children}</div>
}

export function SettingsMenu() {
  const api = useAPIBase()
  const userCtx = useContext(WormholeUserContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      data-testid="settings-menu"
      sx={{ marginRight: "35px" }}
      direction="row"
      spacing={2}
    >
      <StackItem>
        {userCtx?.user && (
          <IconButton onClick={handleClick} aria-label="delete">
            <Avatar data-testid="avatar-btn">
              {getInitials(userCtx?.user?.name)}
            </Avatar>
          </IconButton>
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            data-testid="logout-button"
            onClick={() => {
              handleClose()
              window.location.assign(api("logout"))
            }}
          >
            <LogoutIcon style={{ marginRight: "10px" }} /> Logout
          </MenuItem>
        </Menu>
      </StackItem>
    </Stack>
  )
}
