import { Box, LinearProgress, styled, Typography } from "@mui/material"
import { formatBytes } from "../../util"

type ProgressBarParams = {
  byteRate: number
  progress: number
}

const StyledThroughput = styled("div")({
  marginLeft: "-20px",
  fontSize: "12px",
  marginBottom: "5px",
  textAlign: "center",
  fontWeight: 600,
})

export default function ProgressBar({ byteRate, progress }: ProgressBarParams) {
  return (
    <Box sx={{ width: "100%", marginBottom: "20px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          {byteRate > 0 && (
            <StyledThroughput data-testid="throughput-bytes">
              {formatBytes(byteRate, 0)}/sec
            </StyledThroughput>
          )}
          <LinearProgress value={progress} variant="determinate" />
        </Box>
        <Box
          data-testid="progress-percentage"
          sx={{ minWidth: 35, marginTop: "20px" }}
        >
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
