/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react"
import { Autocomplete, FormControl, TextField } from "@mui/material"
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import UploadDropZone from "../UploadDropZone"
import ProgressBar from "../../../components/ProgressBar"
import {
  useAbortPresignUploadMutation,
  useFinishPresignUploadMutation,
  AbortUpload,
  useGetStudiesQuery,
  useCreatePresignAssetUploadMutation,
} from "../../../generated/graphql"
import { dropAssetHandler, uploadAssetHandler } from "./events"
import { abortUploadHandle } from "../study/events"
import { UploadBox, StyledFormRow, StyledUploadTitle } from "../components"
import { useSetUploadFileStatus } from "../state"
import { handleUnauthorizedError } from "../../../context/userContext"
import SnackAlerts, { snackAlert } from "../../../components/SnackAlerts"

export default function AssetUploadRoute() {
  const navigate = useNavigate()

  // User inputs
  const [studyID, setStudyID] = useState("")

  // Upload state
  const setErrorMsg = useSetRecoilState(snackAlert)
  const [progress, setProgress] = useState(0)
  const [byteRate, setByteRate] = useState(0)
  const [uploadAbort, setUploadAbort] = useState<AbortUpload | null>(null)
  const [createPresignAsset, createAssetMutationResp] =
    useCreatePresignAssetUploadMutation({
      onError: handleUnauthorizedError(navigate),
    })
  const [finishUpload, finishMutationResp] = useFinishPresignUploadMutation()
  const [abortUpload, abortMutationResp] = useAbortPresignUploadMutation()
  const setUploadFileStatus = useSetUploadFileStatus()
  const studies = useGetStudiesQuery({
    onError: handleUnauthorizedError(navigate),
  })

  const putAbortController = useRef<AbortController>()

  // Set error in UI if any of our mutation requests fail
  useEffect(() => {
    const message = `${abortMutationResp.error?.message || ""}${
      finishMutationResp.error?.message || ""
    }${createAssetMutationResp.error?.message || ""}`

    setErrorMsg({
      open: message !== "",
      severity: "error",
      message,
    })
  }, [
    abortMutationResp.error,
    finishMutationResp.error,
    createAssetMutationResp.error,
    setErrorMsg,
  ])

  return (
    <UploadBox elevation={1} className="upload-ui">
      <StyledUploadTitle>
        Asset Upload
        <DriveFolderUploadIcon
          sx={{
            verticalAlign: "bottom",
            height: "40px",
            width: "40px",
            marginLeft: "20px",
            color: "#757575",
          }}
        />
      </StyledUploadTitle>

      <div className="user-input">
        <StyledFormRow>
          <FormControl>
            <Autocomplete
              disablePortal
              options={studies.data?.studies ?? []}
              sx={{}}
              getOptionLabel={(opt) => {
                return `${opt.patientIdentifier} / ${opt.id.substring(
                  0,
                  8
                )} / ${opt.investigationName} / ${opt.siteName}`
              }}
              onChange={(_, change) => setStudyID(change?.id as string)}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Select Study"
                  onChange={(ev) => setStudyID(ev.target.value)}
                />
              )}
            />
          </FormControl>
        </StyledFormRow>
      </div>

      <UploadDropZone
        // dropAssetHandler does nothing currently
        onFileDrop={dropAssetHandler()}
        onUploadClick={uploadAssetHandler({
          studyId: studyID,
          putAbortController,

          setUploadFileStatus,
          createPresignAsset,
          setErrorMsg,
          setByteRate,
          setProgress,
          setUploadAbort,
          finishUpload,
        })}
        onAbort={abortUploadHandle({
          uploadAbort,
          abortUpload,
          putAbortController,
        })}
        uploadBtnLabel="Wormhole Upload"
        maxFiles={100}
        label={
          <>
            Drag &apos;n drop asset files
            <br />
            or click to select
            <span
              style={{
                display: "block",
                fontSize: "11px",
                marginTop: "5px",
              }}
            >
              <strong>Note:</strong> existing asset files will be overwritten
              without prompt
            </span>
          </>
        }
      >
        {progress > 0 && (
          <ProgressBar progress={progress} byteRate={byteRate} />
        )}
      </UploadDropZone>

      <SnackAlerts />
    </UploadBox>
  )
}
