import CssBaseline from "@mui/material/CssBaseline"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
} from "@apollo/client"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { getMainDefinition } from "@apollo/client/utilities"
import { AppMenu, AppMenuItem, SettingsMenu } from "./components/Menu"
import StudyUploadRoute from "./routes/upload/study"
import LoginRoute from "./routes/login"
import { useAPIBase } from "./util"
import { FetchUserContext, RequireAuth } from "./context/userContext"
import AssetUploadRoute from "./routes/upload/asset"
import PodOffloadRoute from "./routes/upload/podoffload"

import "./App.css"

const useSplitApolloClient = () => {
  const apiBase = useAPIBase()
  const wsURL = apiBase("query")

  // Create HTTP link
  const httpLink = new HttpLink({
    uri: wsURL.toString(),
    credentials: "include",
  })

  // Create WebSocket link
  wsURL.protocol = wsURL.protocol === "https:" ? "wss:" : "ws:"
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsURL.toString(),
    })
  )

  // Setup link condition to use WebSocker if subscription
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      )
    },
    wsLink,
    httpLink
  )

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  })
}

function App() {
  const client = useSplitApolloClient()

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <FetchUserContext>
          <div className="hux-app">
            <CssBaseline />

            <header className="hux-header">
              <img
                className="hux-logo"
                alt="Huxley Logo"
                src="/img/HUXLEY_CENTERED_Small.png"
              />

              <AppMenu>
                <AppMenuItem to="/">Manual Study Upload</AppMenuItem>
                <AppMenuItem to="/asset">Asset Upload</AppMenuItem>
                <AppMenuItem to="/sansa">Sansa Device Managment</AppMenuItem>
              </AppMenu>

              <SettingsMenu />
            </header>

            <div className="hux-content">
              <Routes>
                <Route path="/">
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <StudyUploadRoute />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route
                  path="/asset"
                  element={
                    <RequireAuth>
                      <AssetUploadRoute />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/sansa"
                  element={
                    <RequireAuth>
                      <PodOffloadRoute />
                    </RequireAuth>
                  }
                />
                <Route path="/login" element={<LoginRoute />} />
              </Routes>
            </div>
          </div>
        </FetchUserContext>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
