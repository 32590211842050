import { PresignUploadPart } from "./generated/graphql"

export class RetryError extends Error {
  part: PresignUploadPart

  constructor(part: PresignUploadPart) {
    super("retry errror")
    this.part = part
  }
}
