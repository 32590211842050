import { styled } from "@mui/material"

export const FileSizeLabel = styled("div")({
  float: "right",
  fontWeight: "600",
  marginLeft: "10px",
})

export const StyledUploadBox = styled("section")({
  background: "rgba(224, 231, 255, 0.2)",
  border: "3px dashed #e0e7ff",
  borderRadius: "12px",
  padding: "20px",
  textAlign: "center",
  fontSize: "18px",
  fontWeight: 400,
  transition: "0.3s",
  width: "78%",
  margin: "auto",
  backgroundSize: "cover",
  backgroundClip: "padding-box",
  position: ["relative", "relative"],
  marginBottom: "40px",
  marginTop: "40px",
  minHeight: "185px",
  "&:hover": {
    borderColor: "#5a97f7",
  },
})

export const StyledDropZone = styled("div")({
  height: "100%",
  width: "100%",
  position: "absolute",
  left: "0",
  top: "0",
  boxSizing: "border-box",
  paddingTop: "0",
})

export const StyledUploadText = styled("p")({
  marginTop: "60px",
})

export const StyledFileList = styled("div")({
  marginTop: "125px",
  "& ul": {
    listStyleType: "none",
    textAlign: "left",
    margin: "auto",
    display: "inline-block",
    paddingLeft: "0",
  },
  "& ul li": {
    fontSize: "13px",
    marginBottom: "10px",
  },
  "& ul li svg": {
    verticalAlign: "middle",
    marginRight: "5px",
    color: "#5a97f7",
  },
})

export const StyledUploadItem = styled("li")({
  padding: "10px",
  borderRadius: "10px",
})
