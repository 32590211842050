/* eslint-disable react-hooks/exhaustive-deps */
import MemoryIcon from "@mui/icons-material/Memory"
import { useEffect, useReducer, useRef, useState } from "react"
import { Button, Fade, styled } from "@mui/material"
import {
  GetPodSerialRequest,
  GetPodSerialResponse,
  ListAvaliableStudiesRequest,
  ListAvaliableStudiesResponse,
  Study,
} from "@huxley-medical/huxley-grpc/huxley/transfer/v1/transfer_pb"
import { useSetRecoilState } from "recoil"
import { UploadBox } from "../components"
import SnackAlerts, { snackAlert } from "../../../components/SnackAlerts"
import { writeAtCommandFrame, writeCOBSFrame } from "./utils/serial"
import {
  Mode,
  SansaSerialCommandReader,
} from "./utils/serial/SansaSerialCommandReader"
import { createCommandWithChecksum } from "./utils/serial/utils"
import { SansaSerialCommandResponder } from "./utils/serial/SansaSerialCommandResponder"
import { StudyDownloadManager } from "./utils/serial/StudyDownloadManager"
import StudyDownloadCard from "./StudyDownloadCard"

export const usbVendorId = 0x0483
export const usbProductId = 0x5710
export const baudRate = 115200

const StyledSerialConsole = styled("div")({
  backgroundColor: "#000",
  overflowY: "scroll",
  color: "#FFF",
  borderRadius: "5px",
  padding: "10px",
  fontFamily: "monospace",
  fontSize: "12px",
  whiteSpace: "pre",
  height: "100px",
  marginTop: "15px",
})

const detectAndStartConnection = async (port: SerialPort) => {
  // const waitSecs = 0.5

  // console.log("test legacy command")
  // const mode: Mode = (await testIsLegacyCommand(port, waitSecs))
  //   ? "AT_COMMAND"
  //   : "COBS_PROTOBUF"

  const responder = new SansaSerialCommandResponder(port)
  const reader = new SansaSerialCommandReader(port, responder, "AT_COMMAND")

  return { reader, responder }
}

const StyledPodPanelTitle = styled("div")({
  fontSize: "18px",
  fontWeight: "bold",
  color: "#666",
  marginBottom: "20px",
  "& svg": {
    verticalAlign: "middle",
    height: "35px",
    width: "35px",
    marginRight: "10px",
  },
})

const sendListStudiesRequest = async (port: SerialPort) => {
  const listStudiesReq = new ListAvaliableStudiesRequest()
  const cmd = await createCommandWithChecksum(
    listStudiesReq.serializeBinary(),
    "huxley.transfer.v1.ListAvaliableStudiesRequest"
  )
  await writeCOBSFrame(cmd.serializeBinary(), port)()
}

const sendGetPodSerialRequest = async (port: SerialPort) => {
  const getSerial = new GetPodSerialRequest()

  const cmd = await createCommandWithChecksum(
    getSerial.serializeBinary(),
    "huxley.transfer.v1.GetPodSerialRequest"
  )
  writeCOBSFrame(cmd.serializeBinary(), port)()
}

/**
 * useScrollLogToTop scrolls to top of textarea when logOutput changes
 */
export const useScrollLogToTop = ({
  consoleTextarea,
  logOutput,
}: {
  consoleTextarea: React.RefObject<HTMLDivElement>
  logOutput: string
}) => {
  // Scroll to top of textarea when logOutput changes
  useEffect(() => {
    const area = consoleTextarea?.current
    if (area !== null) {
      area.scrollTop = area.scrollHeight
    }
  }, [consoleTextarea, logOutput])
}

type PodPanelParams = {
  port: SerialPort
  refreshPorts: () => void
  registerReader: (reader: SansaSerialCommandReader) => void
}

export default function PodPanel({
  port,
  refreshPorts,
  registerReader,
}: PodPanelParams) {
  const setErrorMsg = useSetRecoilState(snackAlert)
  const [connected, setConnected] = useState(false)
  const [currentMode, setCurrentMode] = useState<Mode>("AT_COMMAND")
  const [readerStarted, setReaderStarted] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const [studies, setStudies] = useState<Study[]>([])
  const [podSerial, setPodSerial] = useState<string>("")

  const readerRef = useRef<SansaSerialCommandReader>()
  const responsederRef = useRef<SansaSerialCommandResponder>()
  const dlMgr = useRef<StudyDownloadManager>()

  const consoleTextarea = useRef<HTMLDivElement>(null)
  const [logOutput, appendLogOutput] = useReducer(
    (oldOutput: string, newOutput: string) => `${oldOutput}${newOutput}\n`,
    ""
  )

  useScrollLogToTop({ consoleTextarea, logOutput })

  const setupReader = async () => {
    // try catch?

    await port.open({
      baudRate,
      flowControl: "hardware",
      bufferSize: 1024 * 1024 * 10,
      stopBits: 1,
      dataBits: 8,
      parity: "none",
    })

    if (port.readable === null) {
      throw new Error("reader is null")
    }

    const { reader, responder } = await detectAndStartConnection(port)

    responder.registerCommandRoute(
      "huxley.transfer.v1.ListAvaliableStudiesResponse",
      {
        anyMsg: ListAvaliableStudiesResponse,
        handler: (resp) => {
          setStudies(resp.getStudiesList())
        },
      }
    )
    responder.registerCommandRoute("huxley.transfer.v1.GetPodSerialResponse", {
      anyMsg: GetPodSerialResponse,
      handler: (resp) => {
        setPodSerial(resp.getSerialId())
      },
    })

    // Setup reader event handlers
    reader.onModeChange = (mode) => setCurrentMode(mode)

    reader.onAtCommandResponse = (value) => {
      console.log("@command:", value)
      appendLogOutput(value)

      if (value.includes("Pod Serial Number: ")) {
        setPodSerial(value.split("Pod Serial Number: ")[1])
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reader.onProtobufResponse = (_cmd) => {
      // console.log("protobuf:", cmd)
      // console.log(cmd.getResult()?.getTypeUrl())
      // cmd.getResult()?.unpack(DeleteStudyRequest.deserializeBinary, )
      // const res = cmd.getResult()
      // res?.pack()
    }
    reader.onErrorResponse = (err) =>
      setErrorMsg({
        open: true,
        message: err.message,
        severity: "error",
      })

    // Save the reader to the ref
    readerRef.current = reader

    // Save the responder to the ref
    responsederRef.current = responder

    // Save the download manager to the ref
    dlMgr.current = new StudyDownloadManager(responder, port)

    dlMgr.current.onDownloadStart = () => setIsDownloading(true)

    dlMgr.current.onDownloadEnd = () => setIsDownloading(false)

    // Start up the reader
    reader.startPortReader()

    // toggle mode in UI state if needed
    if (reader.getMode() !== currentMode) {
      setCurrentMode(reader.getMode())
    }

    // signal UI that the reader is started
    setReaderStarted(true)
    registerReader(reader)
  }

  useEffect(() => {
    if (!connected) {
      // console.log("setup reader")

      // When disconnected, setup the reader?

      setupReader().then(async () => {
        port.addEventListener("disconnect", () => {
          console.log("pod disconnected")

          // tell parent to refresh ports
          refreshPorts()
        })
        setConnected(true)
      })
    }
  }, [connected])

  const initUIMessages = async () => {
    await sendListStudiesRequest(port)
    await sendGetPodSerialRequest(port)
  }

  const refreshStudies = async () => {
    await sendListStudiesRequest(port)
  }

  useEffect(() => {
    if (!connected) return

    writeAtCommandFrame("@pod_serial", port)()

    if (currentMode !== "COBS_PROTOBUF") return

    // console.log("send list studies request")

    initUIMessages()
  }, [connected, currentMode])

  useEffect(() => {
    return () => {
      readerRef.current?.stopPortReader()
    }
  }, [])

  // const modeSwitchHandler = (mode: Mode) => () => {
  //   switch (mode) {
  //     case "COBS_PROTOBUF":
  //       readerRef.current?.switchToProtobuf()
  //       break
  //     case "AT_COMMAND":
  //       readerRef.current?.switchToAtCommand()
  //       break
  //     default:
  //       break
  //   }
  // }

  return (
    <Fade in={connected}>
      <UploadBox
        sx={{
          position: "relative",
          marginTop: "20px",
          marginBottom: "20px",
          "& button": { marginRight: "10px", marginBottom: "10px" },
        }}
        elevation={1}
        className="upload-ui"
      >
        {currentMode === "COBS_PROTOBUF" && (
          <div style={{ float: "right", fontSize: 12, color: "#8a8a8ade" }}>
            {studies.length} studies on device
          </div>
        )}
        {connected && (
          <StyledPodPanelTitle>
            <MemoryIcon
              sx={{
                cursor: "pointer",
                color: "green",
                "&:hover": { color: "#5a97f7" },
              }}
              // onClick={modeSwitchHandler(
              //   currentMode === "COBS_PROTOBUF" ? "AT_COMMAND" : "COBS_PROTOBUF"
              // )}
            />
            {/* {currentMode === "COBS_PROTOBUF" && ( */}
            <>
              Pod{" "}
              <span
                style={{
                  backgroundColor: "#f4f4f4",
                  borderRadius: 3,
                  padding: "5px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                {podSerial || "Unknown"}
              </span>
            </>
            {/* )} */}
          </StyledPodPanelTitle>
        )}

        {readerStarted &&
          currentMode === "COBS_PROTOBUF" &&
          studies
            .sort((a, b) => {
              const aTs = a.getStartTime()?.getSeconds()
              const bTs = b.getStartTime()?.getSeconds()
              if (aTs === undefined || bTs === undefined) return 0
              return bTs - aTs
            })
            .map((study) => (
              <StudyDownloadCard
                key={study.getStudyTimestamp()}
                isDownloading={isDownloading}
                study={study}
                connected={connected}
                dlMgr={dlMgr.current as StudyDownloadManager}
                refreshStudies={refreshStudies}
              />
            ))}

        {readerStarted && currentMode === "AT_COMMAND" && (
          <>
            {/* <Button
              variant="contained"
              disabled={!connected}
              onClick={modeSwitchHandler("COBS_PROTOBUF")}
            >
              @data_offload
            </Button> */}
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@commands", port)}
            >
              @commands
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@dfu", port)}
            >
              @dfu
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@self_test", port)}
            >
              @self_test
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@pod_serial", port)}
            >
              @pod_serial
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@reset_studies", port)}
            >
              @reset_studies
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@version", port)}
            >
              @version
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@device_id", port)}
            >
              @device_id
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@time", port)}
            >
              @time
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={() => {
                writeAtCommandFrame(
                  `@time=${Math.floor(Date.now() / 1000)}`,
                  port
                )()
              }}
            >
              @time=now
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@log_level", port)}
            >
              @log_level
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@test_mode=1", port)}
            >
              @test_mode=1
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@test_mode=0", port)}
            >
              @test_mode=0
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@dynamic_ppg=1", port)}
            >
              @dynamic_ppg=1
            </Button>
            <Button
              variant="contained"
              disabled={!connected}
              onClick={writeAtCommandFrame("@dynamic_ppg=0", port)}
            >
              @dynamic_ppg=0
            </Button>

            <StyledSerialConsole
              role="log"
              data-testid="serial-output"
              ref={consoleTextarea}
            >
              {logOutput || ""}
            </StyledSerialConsole>
          </>
        )}

        <SnackAlerts />
      </UploadBox>
    </Fade>
  )
}
