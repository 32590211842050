import { ApolloError } from "@apollo/client"
import React, { useContext, useEffect } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import {
  useGetUserLazyQuery,
  useGetUserQuery,
  User,
} from "../generated/graphql"

type UserContext = {
  user: User | null
  isLoaded: boolean
}

export const WormholeUserContext = React.createContext<UserContext>({
  user: null,
  isLoaded: false,
})

type FetchUserContextParams = { children: React.ReactNode }

export const handleUnauthorizedError =
  (navigate: NavigateFunction) => (error: ApolloError) => {
    // wormholed will attempt to redirect request to OIDC, but it throws a CORS error
    if (error.networkError?.message?.includes("401")) {
      navigate("/login")
    }
  }

export function FetchUserContext({ children }: FetchUserContextParams) {
  const navigate = useNavigate()

  // Poll every 30 secs to redirect to login
  const [fetchUser] = useGetUserLazyQuery({
    onError: handleUnauthorizedError(navigate),
    pollInterval: 30000,
  })

  const userQuery = useGetUserQuery({
    onError: handleUnauthorizedError(navigate),
  })

  useEffect(() => {
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WormholeUserContext.Provider
      // Warning: The object passed as the value prop to the Context provider (at line 26) changes every render. To fix this consider wrapping it in a useMemo hook.
      // Resolution: This is the intended behavior - useMemo is for caching expensive calculations which this isn't. Or we could refactor to WormholeUserContext be of type:
      // QueryResult<GetUserQuery, Exact<{
      //     [key: string]: never;
      // }>>
      // which IMO would be a leaky abstraction
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user: userQuery.data?.currentUser || null,
        isLoaded: !userQuery.loading || userQuery.error !== undefined,
      }}
    >
      {children}
    </WormholeUserContext.Provider>
  )
}

type RequireAuthParams = { children: JSX.Element }

export function RequireAuth({ children }: RequireAuthParams) {
  const userCtx = useContext(WormholeUserContext)
  const isLoaded = userCtx?.isLoaded
  const user = userCtx?.user

  const navigate = useNavigate()

  useEffect(() => {
    if (user === null && isLoaded) navigate("/login")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoaded])

  if (user !== null) return children

  return null
}
