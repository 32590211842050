import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material"
import { atom, useRecoilState } from "recoil"
import CloseIcon from "@mui/icons-material/Close"

export type SnackAlert = {
  open: boolean
  message: string
  severity?: AlertColor
  autoHideDuration?: number
}

export const defaultNoError: SnackAlert = {
  open: false,
  message: "",
  severity: "warning",
  autoHideDuration: 10000,
}

export const snackAlert = atom<SnackAlert>({
  key: "snackAlert",
  default: defaultNoError,
})

type CloseHandler = (
  event: React.SyntheticEvent | Event,
  reason?: string
) => void

const autoHideDefaultMS = 10000

/**
 * SnackAlerts is used to render alerts in the application, triggered
 * by recoil state variable: snackAlert
 *
 * @returns {JSX.Element}
 */
function SnackAlerts(): JSX.Element {
  const [snackAlertMsg, setSnackAlertMsg] = useRecoilState(snackAlert)

  const handleClose: CloseHandler = (_, reason) => {
    if (reason === "clickaway") {
      return
    }
    setSnackAlertMsg({ ...snackAlertMsg, open: false })
  }

  return (
    <Snackbar
      open={snackAlertMsg.open}
      autoHideDuration={snackAlertMsg.autoHideDuration ?? autoHideDefaultMS}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        ".MuiPaper-elevation": {
          border: "1.5px solid #D47171",
        },
        ".MuiAlert-icon": {
          marginTop: "3px",
        },
      }}
    >
      <Alert
        severity={snackAlertMsg.severity ?? "warning"}
        sx={{
          width: "100%",
          boxShadow: "rgba(149, 157, 165, 0.4) 0px 8px 24px;",
        }}
      >
        {snackAlertMsg.message}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          sx={{ marginLeft: "5px" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Alert>
    </Snackbar>
  )
}

export default SnackAlerts
