import { Blob } from "buffer"

type HSTMetadataV0 = {
  firmware: {
    hash: string
    version: string
  }

  timestamp: number
  version: number
}

type HeaderSpecField =
  | "magicNumber"
  | "majorVersion"
  | "minorVersion"
  | "metadataLength"
  | "metadataVersion"

export default class HSTParser {
  file: Blob

  dataView: undefined | DataView

  littleEndian = false

  metadataStart = 12

  huxMagicNumber = "HXLY"

  headerSpec: {
    [key in HeaderSpecField]: Array<number>
  } = {
    magicNumber: [0, 3],
    majorVersion: [4, 5],
    minorVersion: [6, 7],
    metadataLength: [8, 9],
    metadataVersion: [10, 11],
  }

  constructor(file: Blob) {
    this.file = file
  }

  async initDataView() {
    if (this.dataView === undefined)
      this.dataView = new DataView(await this.file.arrayBuffer())
  }

  async getVersion(): Promise<number[]> {
    await this.initDataView()

    const major = (this.dataView as DataView).getUint16(
      this.headerSpec.majorVersion[0],
      this.littleEndian
    )

    const minor = (this.dataView as DataView).getUint16(
      this.headerSpec.minorVersion[0],
      this.littleEndian
    )

    return [major, minor]
  }

  async getMetadata(): Promise<HSTMetadataV0> {
    await this.initDataView()

    const length = (this.dataView as DataView).getUint16(
      this.metadataStart,
      this.littleEndian
    )

    // Metadata JSON starts after metadata length
    const metadataStartJSON = this.metadataStart + 2

    return JSON.parse(
      new TextDecoder().decode(
        this.dataView?.buffer.slice(
          metadataStartJSON,
          metadataStartJSON + length
        )
      )
    )
  }

  async getHeaderFieldText(fieldName: HeaderSpecField): Promise<any> {
    await this.initDataView()

    const sliceParams = this.headerSpec[fieldName]

    return new TextDecoder().decode(
      this.dataView?.buffer.slice(sliceParams[0], sliceParams[1] + 1)
    )
  }

  async isValidSansaDataFile(): Promise<boolean> {
    // generic sanity check, prevent folder uploads (.app)
    try {
      await this.initDataView()
    } catch {
      return false
    }

    return (
      (await this.getHeaderFieldText("magicNumber")) === this.huxMagicNumber
    )
  }
}
