/* eslint-disable no-underscore-dangle */
// required for Stream.Duplex _read and _write callbacks

// TypeScript refactor of
// https://github.com/tcr/node-cobs

/**
 * encode COBS encodes the given Uint8Array
 *
 * @param buf
 * @param zeroFrame
 * @returns {Uint8Array}
 */
export function encode(
  buf: Uint8Array,
  zeroFrame: boolean = false
): Uint8Array {
  const dest = [0]
  let codePtr = 0
  let code = 0x01

  if (zeroFrame) {
    dest.push(0x00)
    codePtr += 1
  }

  const finish = (omitLast: boolean = false) => {
    dest[codePtr] = code
    codePtr = dest.length
    if (!omitLast) dest.push(0x00)
    code = 0x01
  }

  for (let i = 0; i < buf.byteLength; i += 1) {
    if (buf.at(i) === 0) {
      finish()
    } else {
      dest.push(buf.at(i) as number)
      code += 1
      if (code === 0xff) {
        finish()
      }
    }
  }

  finish(true)

  if (zeroFrame) {
    dest.push(0x00)
  }

  return new Uint8Array(dest)
}

/**
 * trimZeroPrefix trims 0x0 from beginning of given Uint8Array
 *
 * @param buf
 * @returns {Uint8Array}
 */
export function trimZeroPrefix(buf: Uint8Array): Uint8Array {
  let hitNonZero = false
  let i = 0

  while (!hitNonZero) {
    const code = buf.at(i) as number
    if (code !== 0) {
      hitNonZero = true
    } else {
      i += 1
    }
  }

  return buf.slice(i)
}

/**
 * trimZeroSuffix trims 0x0 from end of given Uint8Array
 *
 * @param buf
 * @returns {Uint8Array}
 */
export function trimZeroSuffix(buf: Uint8Array): Uint8Array {
  let hitNonZero = false
  let i = buf.byteLength - 1

  while (!hitNonZero) {
    const code = buf.at(i) as number
    if (code !== 0) {
      hitNonZero = true
    } else {
      i -= 1
    }
  }

  return buf.slice(0, i + 1)
}

/**
 * decode COBS decodes the given Uint8Array
 *
 * @param buf
 * @param zeroFrame
 * @returns {Uint8Array}
 */
export function decode(
  buf: Uint8Array,
  zeroFrame: boolean = false
): Uint8Array {
  const dest = []

  let inputBuf: Uint8Array = buf

  if (zeroFrame) {
    inputBuf = trimZeroSuffix(trimZeroPrefix(buf))
  }

  for (let i = 0; i < inputBuf.byteLength; ) {
    const code = inputBuf.at(i) as number
    i += 1

    for (let j = 1; j < code; j += 1) {
      dest.push(inputBuf.at(i) as number)
      i += 1
    }
    if (code < 0xff && i < inputBuf.byteLength) {
      dest.push(0)
    }
  }
  return new Uint8Array(dest)
}
