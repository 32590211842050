/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from "react"
import {
  Autocomplete,
  Button,
  Chip,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Tooltip,
} from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import momentTimezone from "moment-timezone"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import BedtimeIcon from "@mui/icons-material/Bedtime"
import { useNavigate } from "react-router-dom"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { useSetRecoilState } from "recoil"
import { useApolloClient } from "@apollo/client"
import UploadDropZone from "../UploadDropZone"
import ProgressBar from "../../../components/ProgressBar"
import {
  useAbortPresignUploadMutation,
  useCreatePresignStudyUploadMutation,
  useFinishPresignUploadMutation,
  AbortUpload,
  useGetSitesQuery,
  useGetSubjectsQuery,
  useGetInvestigationsQuery,
} from "../../../generated/graphql"
import {
  abortUploadHandle,
  dropHandler,
  handleTZChange,
  uploadHandler,
} from "./events"
import { UploadBox, StyledFormRow, StyledUploadTitle } from "../components"
import { useSetUploadFileStatus } from "../state"
import { handleUnauthorizedError } from "../../../context/userContext"
import SnackAlerts, { snackAlert } from "../../../components/SnackAlerts"

// TODO: Pull from database
const defaultTempTagSuggestions = ["Sample Tag 1", "Tag 2"]

const StyledMetadataViewContent = styled("pre")({
  backgroundColor: "#eeeeee",
  fontFamily: "monospace",
  padding: "15px",
  borderRadius: "15px",
  overflow: "scroll",
  fontSize: "12px",
})

const StyledMetadataView = styled("div")({
  width: "78%",
  margin: "auto",
})

function MetadataView({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <StyledMetadataView>
      <div style={{ textAlign: "right" }}>
        <Button
          onClick={handleClick}
          onKeyDown={handleClick}
          style={{ cursor: "pointer" }}
        >
          {open ? "Hide" : "Show"} Study Metadata
          {open ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon sx={{ verticalAlign: "top" }} />
          )}
        </Button>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <StyledMetadataViewContent>{children}</StyledMetadataViewContent>
      </Collapse>
    </StyledMetadataView>
  )
}

export default function StudyUploadRoute() {
  const momentAdapter = new AdapterMoment({ instance: momentTimezone })
  const navigate = useNavigate()

  // user inputs
  const [timezone, setTimezone] = useState(moment.tz.guess())
  const [subjectID, setSubjectID] = useState("")

  // Must call .format() here, usage of Moment object doesn't pick up timezone change (cached?)
  const initialDateTime: string = momentAdapter.moment().tz(timezone).format()
  const [studyTimestamp, setStudyTimestamp] = useState<
    string | null | undefined
  >(initialDateTime)
  const [hdpMetadata, setHdpMetadata] = useState<string>("{}")

  const putAbortController = useRef<AbortController>()

  const setErrorMsg = useSetRecoilState(snackAlert)
  const [progress, setProgress] = useState(0)
  const [byteRate, setByteRate] = useState(0)
  const [uploadAbort, setUploadAbort] = useState<AbortUpload | null>(null)
  const [createPresign, createMutationResp] =
    useCreatePresignStudyUploadMutation({
      onError: handleUnauthorizedError(navigate),
    })
  const [finishUpload, finishMutationResp] = useFinishPresignUploadMutation()
  const [abortUpload, abortMutationResp] = useAbortPresignUploadMutation()

  // Use client to subscribe to invokeProcessingResult
  // so we don't have to deal with complex state management using hooks
  const client = useApolloClient()

  const sitesResult = useGetSitesQuery({
    onError: handleUnauthorizedError(navigate),
  })
  const investigationResult = useGetInvestigationsQuery({
    onError: handleUnauthorizedError(navigate),
  })
  const subjectsResult = useGetSubjectsQuery({
    onError: handleUnauthorizedError(navigate),
  })

  const setUploadFileStatus = useSetUploadFileStatus()

  const defaultSiteID = sitesResult.data?.sites?.find(
    (s) => s.name === "Huxley" || s.name === "Test Site"
  )?.id

  const defaultInvestigationID = investigationResult.data?.investigations?.find(
    (s) => s.name === "Internal" || s.name === "John Investigation"
  )?.id

  const [investigationID, setInvestigationID] = useState(defaultInvestigationID)

  const [siteID, setSiteID] = useState(defaultSiteID)
  const [tags, setTags] = useState<string[]>([])

  // Set error in UI if any of our mutation requests fail
  useEffect(() => {
    const message = `${abortMutationResp.error?.message || ""}${
      finishMutationResp.error?.message || ""
    }${createMutationResp.error?.message || ""}`

    setErrorMsg({
      open: message !== "",
      severity: "error",
      message,
    })
  }, [
    abortMutationResp.error,
    finishMutationResp.error,
    createMutationResp.error,
    setErrorMsg,
  ])

  return (
    <UploadBox elevation={1} className="upload-ui">
      <StyledUploadTitle>
        Sleep Study Upload
        <BedtimeIcon
          sx={{
            verticalAlign: "bottom",
            height: "40px",
            width: "40px",
            marginLeft: "20px",
            color: "#EBC815",
          }}
        />
      </StyledUploadTitle>

      <div className="user-input">
        <StyledFormRow>
          <FormControl>
            <Tooltip
              title="Subject will be created, if given identifier doesn't exist. Otherwise the study will be linked to existing subject."
              placement="top"
            >
              <Autocomplete
                freeSolo
                options={subjectsResult.data?.subjects ?? []}
                getOptionLabel={(opt) => {
                  if (typeof opt === "string") {
                    return opt
                  }
                  return opt.identifier
                }}
                onChange={(_, subject) => {
                  if (typeof subject === "string" || subject === null) return
                  setSubjectID(subject.identifier)
                }}
                renderInput={(params) => (
                  <TextField
                    label="Subject Identifier"
                    data-testid="subject-id-input"
                    variant="outlined"
                    onChange={(event) => {
                      setSubjectID(event.target.value)
                    }}
                    {...params}
                  />
                )}
              />
            </Tooltip>
          </FormControl>
          <FormControl>
            <InputLabel>Study Site</InputLabel>
            <Select
              data-testid="site-id-input"
              value={siteID ?? defaultSiteID ?? ""}
              label="Study Site"
              onChange={(ev) => setSiteID(ev.target.value)}
            >
              {sitesResult.data?.sites.map((site) => {
                return (
                  <MenuItem key={site.id} value={site.id}>
                    {site.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </StyledFormRow>

        <StyledFormRow>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              renderInput={(props: any) => (
                <TextField
                  data-testid="study-timestamp-picker"
                  className="study-timestamp-picker"
                  // Resolution: idiomatic usage of prop spreading for DateTimePicker
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
              value={studyTimestamp}
              label="Study Timestamp"
              inputFormat="YYYY/MM/DD HH:mm:ss"
              mask="____/__/__ __:__:__"
              onChange={(newValue: string | null) => {
                setStudyTimestamp(moment(newValue).tz(timezone).format())
              }}
            />
          </LocalizationProvider>

          <FormControl>
            <InputLabel>Timezone</InputLabel>
            <Select
              value={timezone}
              label="Timezone"
              onChange={handleTZChange({ setTimezone })}
            >
              {moment.tz.zonesForCountry("US").map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledFormRow>
        <StyledFormRow>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel>Investigation</InputLabel>
            <Select
              value={investigationID ?? defaultInvestigationID ?? ""}
              label="Investigation"
              onChange={(ev) => setInvestigationID(ev.target.value)}
              data-testid="investigation-id-input"
            >
              {investigationResult.data?.investigations.map((i) => {
                return (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Tooltip
            placement="top"
            title="Can be used for capturing data that can be used to instruct future DSP pipelines downstream. Or capture fields that don't yet exist. E.g. run-dsp-v2.1, omit-spo2, DRYYYYMMDD-P-Rev, EDS-xxxxx-Rev"
          >
            <Autocomplete
              multiple
              id="tags-filled"
              sx={{ flex: 1 }}
              options={defaultTempTagSuggestions}
              value={tags}
              freeSolo
              onChange={(_, changedTags) => {
                setTags(changedTags)
              }}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // variant="filled"
                  label="Optional Tags"
                  placeholder="Press enter to save tag"
                />
              )}
            />
          </Tooltip>
        </StyledFormRow>
      </div>

      {hdpMetadata !== "{}" && <MetadataView>{hdpMetadata}</MetadataView>}

      <UploadDropZone
        onFileDrop={dropHandler({
          setErrorMsg,
          setStudyTimestamp,
          setHdpMetadata,
          momentAdapter,
          timezone,
        })}
        onUploadClick={uploadHandler({
          client,
          tags,
          investigationID: investigationID ?? defaultInvestigationID,
          siteID: siteID ?? defaultSiteID,
          subjectID,
          timezone,
          studyTimestamp: studyTimestamp || "",
          hdpMetadata,
          setUploadFileStatus,
          setProgress,
          setByteRate,
          setErrorMsg,
          createPresign,
          setUploadAbort,
          finishUpload,
          putAbortController,
        })}
        onAbort={abortUploadHandle({
          uploadAbort,
          abortUpload,
          putAbortController,
        })}
        uploadBtnLabel="Wormhole Upload"
        label={
          <>
            Drag &apos;n drop .hst or .hdp file
            <br />
            here or click to select
          </>
        }
      >
        {progress > 0 && (
          <ProgressBar progress={progress} byteRate={byteRate} />
        )}
      </UploadDropZone>

      <SnackAlerts />
    </UploadBox>
  )
}
