import LockOpenIcon from "@mui/icons-material/LockOpen"
import { Paper, styled } from "@mui/material"
import { useAPIBase } from "../../util"

export const LoginBox = styled(Paper)({
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  borderRadius: "12px",
  textAlign: "center",
  padding: "30px",
})

export default function LoginRoute() {
  const apiBase = useAPIBase()

  return (
    <LoginBox>
      <h4 className="upload-form-title">
        Sign in to Wormhole
        <LockOpenIcon
          style={{
            verticalAlign: "sub",
            height: "30px",
            width: "30px",
            marginLeft: "10px",
          }}
        />
      </h4>

      <a
        role="button"
        href={`${apiBase("login")}?redirect=${new URL(
          "/",
          document.location.toString()
        ).toString()}`}
      >
        <img alt="Sign In" src="./img/google-signin-btn.png" />
      </a>
    </LoginBox>
  )
}
