import { Box, Paper, styled } from "@mui/material"

export const StyledFormRow = styled(Box)({
  width: "78%",
  display: "flex",
  columnGap: "15px",
  margin: "auto",
  marginBottom: "20px",
  "& .MuiFormControl-root": {
    flex: 1,
  },
})

export const StyledUploadTitle = styled("h4")({
  // float: "right",
  textAlign: "center",
  fontSize: "22px",
  color: "#757575",
  marginBottom: "30px",
  "& svg": {
    verticalAlign: "middle",
    height: "30px",
    width: "30px",
  },
})

export const UploadBox = styled(Paper)({
  borderRadius: "12px",
  padding: "30px",
  boxSizing: "border-box",
  boxShadow:
    "0px 2px 1px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%)",

  margin: "auto",
  marginTop: "40px",
  marginBottom: "30px",
  width: "70%",
})
