/* eslint-disable react/no-unescaped-entities */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"

interface DeletePromptDialogProps {
  studyName: string
  open: boolean
  onClose: () => void
  onDelete: () => void
}

function DeletePromptDialog({
  studyName,
  open,
  onClose,
  onDelete,
}: DeletePromptDialogProps) {
  const handleClose = () => {
    onClose()
  }

  const handleDelete = () => {
    onDelete()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to delete study "{studyName}" from the device?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePromptDialog
