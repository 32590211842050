const DEFAULT_API_ENDPOINT = "http://localhost:8080"

export function formatBytes(bytes: number | undefined | null, decimals = 2) {
  let byteNum: number

  if (bytes === undefined || bytes === null) {
    byteNum = 0
  } else {
    byteNum = bytes
  }

  if (byteNum === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(byteNum) / Math.log(k))

  return `${parseFloat((byteNum / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function getInitials(nameStr: string | undefined | null) {
  // from: https://stackoverflow.com/a/33076323

  if (nameStr === undefined || nameStr === null) return ""

  const names = nameStr.split(" ")
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

export const sleep = async (timeoutSecs: number) => {
  let waitSecs = timeoutSecs

  if (timeoutSecs < 0) waitSecs = 0

  await new Promise((r) => {
    setTimeout(r, 1000 * waitSecs)
  })
}

function apiEndpoint(): URL {
  // Get the GraphQL API URL
  // Start with runtime config (written at docker container startup)
  // failback to build-time REACT_APP env vars
  // failback to hardcoded development value
  const endpoint =
    // Intended variable name to reduce future collision risk on window object
    // eslint-disable-next-line no-underscore-dangle
    (window as any)._hux_runtime_config?.apiEndpoint ||
    process.env.REACT_APP_API_ENDPOINT ||
    DEFAULT_API_ENDPOINT

  const endpointUrl = new URL(endpoint)

  // Ensure the endpoint ends in a forward slash to guarantee that subpaths
  // will be appended to the existing URL rather than replacing the last part
  // of the path
  if (endpointUrl.pathname.slice(-1) !== "/") {
    endpointUrl.pathname += "/"
  }

  return endpointUrl
}

// useAPIBase is a hook that allows you to construct URLs for usage in fetch or Apollo client constructor, etc... with
// a given sub-path path, based off of the configured APIBase (REACT_APP_API_ENDPOINT, or container runtime config)
export const useAPIBase = () => {
  const base = apiEndpoint()

  return (subPath: string) => {
    return new URL(subPath, base)
  }
}
