import { DropEvent, FileRejection, FileWithPath } from "react-dropzone"
import { UploadEvent, UploadFile, UploadStatus, VoidCallback } from "../types"

type UploadClickHandlerParams = {
  setGlobalUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
  onUploadClick: (uploadParams: UploadEvent) => void | Promise<void>
  uploadEvent: UploadEvent | undefined
}

/**
 * uploadClickHandler is invoked when the upload button is clicked in the UploadDropZone
 * @param {UploadClickHandlerParams} uploadClickHandlerParams
 * @returns {VoidCallback}
 */
export const uploadClickHandler =
  ({
    uploadEvent,
    setGlobalUploadStatus,
    onUploadClick,
  }: UploadClickHandlerParams): VoidCallback =>
  async () => {
    try {
      // onUploadClick will always have a value if this handler is invoked
      // do check for cleaner syntax than 'as'
      if (onUploadClick !== undefined) {
        setGlobalUploadStatus("inprogress")
        await onUploadClick(uploadEvent as UploadEvent)
        setGlobalUploadStatus("complete")
      }
    } catch (err) {
      setGlobalUploadStatus("waiting")
      // console.log(err)
    }
  }

type OnDropCallbackParams = {
  setUploadEvent: React.Dispatch<React.SetStateAction<UploadEvent | undefined>>
  setUploadFiles: (newFiles: UploadFile[]) => void
  setGlobalUploadStatus: React.Dispatch<React.SetStateAction<UploadStatus>>
  handleUploadEventCallback: HandleUploadEventCallback
}

type DropZoneHandler = (
  acceptedFiles: File[],
  fileRejections: FileRejection[],
  event: DropEvent
) => Promise<void>

/**
 * onDropCallback is called when files are dropped in the UploadDropZone. Can be used as a
 * pre-filter for validation.
 *
 * @param {OnDropCallbackParams} onDropCallbackParams
 * @returns {DropZoneHandler}
 */
export const onDropCallback =
  ({
    setUploadEvent,
    setUploadFiles,
    setGlobalUploadStatus,
    handleUploadEventCallback,
  }: OnDropCallbackParams): DropZoneHandler =>
  async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    // Detect files dropped from containing folder
    const folderFiles = acceptedFiles.filter((f: FileWithPath) => {
      return f.path?.includes("/") === true
    })

    // Remove duplicates if directories were expanded when dropped from list view in Finder
    const files = acceptedFiles.filter((f: FileWithPath) => {
      const foundMatch = folderFiles.find(
        (ff) =>
          ff.name === f.name &&
          ff.size === f.size &&
          ff.lastModified === f.lastModified
      )
      return !foundMatch
    })

    const uploadFiles: UploadFile[] = [...folderFiles, ...files].map((f) => ({
      file: f,
      status: "waiting",
    }))

    const uploadEvent: UploadEvent = {
      acceptedFiles: uploadFiles,
      fileRejections,
      event,
    }

    // Check to see if the file is valid study
    // if not, will raise exception
    try {
      await handleUploadEventCallback({ uploadEvent })
    } catch (e) {
      console.log(e)
      return
    }

    setUploadEvent(uploadEvent)
    setGlobalUploadStatus("waiting")
    setUploadFiles(uploadFiles)
  }

type HandleUploadEventParams = {
  onFileDrop: (uploadParams: UploadEvent) => void | Promise<void>
}

type HandleUploadEventCallback = ({
  uploadEvent,
}: HandleUploadEventCallbackParams) => Promise<void>

type HandleUploadEventCallbackParams = {
  uploadEvent: UploadEvent | undefined
}

/**
 * handleUploadEvent is invoked when uploadEvent state changes in the UploadDropZone.
 * Which happens when files are successfully dropped or selected. Is syntax sugar, no
 * functionality but invokes the onFileDrop function.
 *
 * @param {HandleUploadEventParams} handleUploadEventParams
 * @returns {HandleUploadEventCallback}
 */
export const handleUploadEvent =
  ({ onFileDrop }: HandleUploadEventParams): HandleUploadEventCallback =>
  async ({ uploadEvent }: HandleUploadEventCallbackParams): Promise<void> => {
    if (uploadEvent === undefined) return

    const { acceptedFiles, fileRejections, event } = uploadEvent

    await onFileDrop({
      acceptedFiles,
      fileRejections,
      event,
    })
  }
