/* eslint-disable class-methods-use-this */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  CommandOutput,
  ExitDataOffloadRequest,
} from "@huxley-medical/huxley-grpc/huxley/transfer/v1/transfer_pb"
// import { decode } from "../cobs"
import { Any } from "google-protobuf/google/protobuf/any_pb"
import { writeAtCommandFrame, writeCOBSFrame } from "."
import { createCommandWithChecksum, hashData } from "./utils"
import { ProtobufError } from "./ProtobufError"
import { SansaSerialCommandResponder } from "./SansaSerialCommandResponder"
import { decode } from "../cobs"

export const dataOffloadSuccessRespString = "COBS-Protobuf"

export type Mode = "COBS_PROTOBUF" | "AT_COMMAND"

/**
 * SansaSerialCommandReader is a class for reading data from a serial port
 * and parsing it into a mode and response type
 * @class
 * @param {SerialPort} port
 * @param {Mode} mode
 * @returns {SansaSerialCommandReader}
 */
export class SansaSerialCommandReader {
  // instance variables
  private port: SerialPort
  private reader: ReadableStreamDefaultReader<Uint8Array> | undefined
  private mode: Mode
  private responser: SansaSerialCommandResponder

  // event handlers
  public onModeChange?: (mode: Mode) => void
  public onAtCommandResponse?: (value: string) => void
  public onProtobufResponse?: (value: CommandOutput) => void
  public onErrorResponse?: (err: ProtobufError) => void

  // output buffers
  private serialOutputCOBS: number[] = []
  private serialOutputAtCommand: number[] = []

  /**
   * constructor for SansaSerialCommander
   * @constructor
   * @param {SerialPort} port
   * @param {Mode} mode
   * @returns {SansaSerialCommander}
   */
  constructor(
    port: SerialPort,
    responder: SansaSerialCommandResponder,
    mode: Mode = "AT_COMMAND"
  ) {
    this.port = port
    this.mode = mode
    this.responser = responder
  }

  /**
   * getMode returns the current mode
   * @returns {Mode}
   */
  public getMode(): Mode {
    return this.mode
  }

  /**
   * getResponder returns the responser
   *
   * @returns {SansaSerialCommandResponder}
   */
  public getResponder(): SansaSerialCommandResponder {
    return this.responser
  }

  /**
   * validateChecksum validates the checksum of a CommandOutput protobuf message.
   *
   * @param {CommandOutput} cmd
   * @returns {Promise<boolean>}
   */
  private async validateChecksum(cmd: CommandOutput): Promise<boolean> {
    const checksum = cmd.getChecksum()
    if (checksum === undefined) {
      return false
    }

    const alg = checksum.getAlgorithm()
    const sum = checksum.getSum()
    if (alg === undefined || sum === undefined) {
      return false
    }

    const result = cmd.getResult()
    if (result === undefined) {
      return false
    }

    const messageBinary = result.getValue()
    const hash = await hashData(messageBinary as Uint8Array, alg)
    if (hash.toString() !== sum.toString()) {
      return false
    }

    return true
  }

  /**
   * portReadHandlerAtCommand builds @command frames
   *
   * @param {Uint8Array} value
   * @returns {void}
   */
  private portReadHandlerAtCommand(value: Uint8Array): void {
    // build the serialOutputAtCommand
    for (let i = 0; i < value.byteLength; i += 1) {
      const data = value.at(i) as number

      // build output
      if (String.fromCharCode(data) !== "\n")
        this.serialOutputAtCommand.push(data)

      // break, we have a message
      if (
        String.fromCharCode(data) === "\n" &&
        this.serialOutputAtCommand.length > 0
      ) {
        const respStr = new TextDecoder().decode(
          new Uint8Array(this.serialOutputAtCommand)
        )

        this.onAtCommandResponse?.(respStr)

        if (respStr.includes(dataOffloadSuccessRespString)) {
          console.log("switching to COBS mode")
          this.mode = "COBS_PROTOBUF"
          this.onModeChange?.(this.mode)
        }

        // reset and continue processing for next frame
        this.serialOutputAtCommand = []
      }
    }
  }

  /**
   * portReadHandlerCOBSProtobuf builds COBS frames. It assumes that the frames are
   * delimited by a 0x00 byte (COBS encoded). It will decode the frame into a
   * protobuf message and call the onProtobufResponse handler.
   *
   * @param {Uint8Array} value
   * @returns {void}
   */
  private portReadHandlerCOBSProtobuf(value: Uint8Array): void {
    // build the serialOutputCOBS
    for (let i = 0; i < value.byteLength; i += 1) {
      const data = value.at(i) as number

      // build output
      if (data !== 0) this.serialOutputCOBS.push(data)

      // break, we have a message
      if (data === 0 && this.serialOutputCOBS.length > 0) {
        this.handleCOBSResponse(this.serialOutputCOBS)
        this.serialOutputCOBS = []
      }
    }
  }

  async handleCOBSResponse(frame: number[]) {
    // Decode the frame
    const decodedCobsFrame = decode(new Uint8Array(frame), true)

    this.serialOutputCOBS = []

    let cmd: CommandOutput
    try {
      cmd = CommandOutput.deserializeBinary(decodedCobsFrame)
      // console.log("success")
      // console.log("success", new Uint8Array(this.serialOutputCOBS))
    } catch (error) {
      console.log("fail")
      // console.log(new Uint8Array(this.serialOutputCOBS))
      // console.log("fail", new Uint8Array(this.serialOutputCOBS))
      // console.log(error)
      return
    }

    // check if we have an error
    if (cmd.hasErr()) {
      throw new ProtobufError(cmd.getErr()?.getReason())
    }

    // check if we need to switch to AT_COMMAND mode
    const typeURL = cmd.getResult()?.getTypeUrl()
    if (typeURL === "huxley.transfer.v1.ExitDataOffloadResponse") {
      console.log("switching to AT_COMMAND mode")
      this.mode = "AT_COMMAND"
      this.onModeChange?.(this.mode)
      return
    }

    const isValidChecksum = await this.validateChecksum(cmd)
    if (!isValidChecksum) {
      console.log("invalid checksum")
      return
    }

    this.responser.routeAnyCommand(cmd.getResult() as Any)
    this.onProtobufResponse?.(cmd)
  }

  /**
   * stopPortReader stops the port reader
   *
   * @returns {void}
   */
  public async stopPortReader(): Promise<void> {
    try {
      await this.reader?.cancel()
      // eslint-disable-next-line no-empty
    } catch {}
  }

  /**
   * startPortReader starts the port reader
   *
   * @returns {Promise<void>}
   * @throws {Error} if port reader is undefined
   */
  public async startPortReader(): Promise<void> {
    // console.log("starting port reader")

    this.reader = this.port.readable?.getReader()
    if (this.reader === undefined) {
      console.log("reader is undefined")
      return
    }

    // eslint-disable-next-line no-unreachable-loop
    while (this.reader) {
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          //  console.log("READING CHUNK")
          // eslint-disable-next-line no-await-in-loop
          const { value, done } = await this.reader.read()
          if (done) {
            break
          }
          // console.log("GOT CHUNK")

          if (this.mode === "AT_COMMAND") {
            this.portReadHandlerAtCommand(value)
          } else {
            this.portReadHandlerCOBSProtobuf(value)
          }
        }
      } catch (error) {
        if (error instanceof ProtobufError) {
          this.onErrorResponse?.(error)
          // reset and continue processing for next frame
          this.serialOutputCOBS = []
          continue
        } else {
          // expected error when reader is released
          if (!(error as any).message.includes("has been released")) {
            console.log(error)
            console.log("reader error, exiting")
          } else {
            // console.log("reader released, exiting")
          }

          break
        }
      }

      this.reader.releaseLock()
    }

    this.port.close()
    // console.log("reader closed")
  }

  /**
   * switchToProtobuf switches the reader to COBS-Protobuf mode
   *
   * @returns {void}
   */
  public switchToProtobuf(): void {
    writeAtCommandFrame("@data_offload", this.port)()
  }

  /**
   * switchToAtCommand switches the reader to @command mode
   *
   * @returns {Promise<void>}
   */
  public async switchToAtCommand(): Promise<void> {
    const exitReq = new ExitDataOffloadRequest()

    const cmd = await createCommandWithChecksum(
      exitReq.serializeBinary(),
      "huxley.transfer.v1.ExitDataOffloadRequest"
    )

    writeCOBSFrame(cmd.serializeBinary(), this.port)()
  }
}
